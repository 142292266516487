import { FormControl, Input } from "@mui/material";


interface ExtendedAutocompleteLineProps {
    fullSentence: string,
    paramsState: [string[], (newParams: string[]) => void]
}

export default function ExtendedAutocompleteLine(props: ExtendedAutocompleteLineProps) {
    const updateParams = (value: string, index: number) => {
        props.paramsState[1](addToArrayIndex(props.paramsState[0], index, value));
    };
    const dotsReplaced = props
        .fullSentence
        .split("...")
        .map<React.ReactNode>(t => <span>{t}</span>)
        .reduce((prev, curr, i) => [prev,
            <>
                &nbsp;
                <FormControl>
                    <Input name={"lineInput" + i} sx={{ width: "6em" }} value={props.paramsState[0][i - 1]} onChange={e => updateParams(e.target.value, i - 1)} />
                </FormControl>
            </>,
            curr]);

    return (
        <>
            {dotsReplaced}
        </>
    );
}


function addToArrayIndex(a: string[], index: number, item: string) {
    if (a.length > index) {
        a.splice(index, 1, item);
    }
    else {
        const aLength = a.length;
        for (let i = 0; i < index - aLength; i++) {
            a.push("");
        }
        a.push(item);
    }
    return a;
}