import { ReactNode } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material';
import styles from './HideableContent.module.css';

interface HideableContentProps {
    children: ReactNode,
    title: ReactNode,
    defaultHidden?: boolean,
    outerProps?: Partial<AccordionProps>
}

export default function HideableContent(props: HideableContentProps) {

    return (
        <Accordion
            defaultExpanded={!props.defaultHidden}
            disableGutters
            elevation={0}
            square
            variant="outlined"
            className={styles.outer}
            {...props.outerProps}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                    fontSize: 18
                }}
            >
                {props.title}
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </Accordion>
    );
}