import { Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';

interface InfoTooltipProps {
    text: string
}

export default function InfoTooltip(props: InfoTooltipProps) {
    return <Tooltip title={props.text}>
        <Info style={{ color: "#ccc" }} fontSize="small" />
    </Tooltip>;
}