import React, { Dispatch, SetStateAction } from "react";
import PackagingDto from "../dtos/PackagingDto";
import NewChemicalModel from "../models/NewChemicalModel";
import NewLocationModel from "../models/NewLocationModel";

interface INewApplicationContext {
    chemical: {
        selectedIdState: [string, Dispatch<SetStateAction<string>>],
        isFormValidState: [boolean, Dispatch<SetStateAction<boolean>>],
        modelState: [NewChemicalModel, Dispatch<SetStateAction<NewChemicalModel>>]
    }
    location: {
        selectedIdState: [string, Dispatch<SetStateAction<string>>],
        isFormValidState: [boolean, Dispatch<SetStateAction<boolean>>],
        modelState: [NewLocationModel, Dispatch<SetStateAction<NewLocationModel>>]
    }
    packaging: {
        selectedIdState: [string, Dispatch<SetStateAction<string>>],
        isFormValidState: [boolean, Dispatch<SetStateAction<boolean>>],
        modelState: [PackagingDto, Dispatch<SetStateAction<PackagingDto>>]
    }
    quantityState: [number, Dispatch<SetStateAction<number>>]
}

export const NewApplicationContext = React.createContext<INewApplicationContext>({} as INewApplicationContext);