export default class LocalStorageHandler {

    private static handlers: LocalStorageHandler[] = [];

    private readonly _StorageName: string;
    private readonly _Separator: string;

    private constructor(storageName: string, separator: string) {
        this._StorageName = storageName;
        this._Separator = separator;
        
        LocalStorageHandler.handlers.push(this);
    }

    public static getHandler(storageName: string): LocalStorageHandler {
        const handler = this.handlers.find(h => h._StorageName === storageName);
        if (!handler)
            return new LocalStorageHandler(storageName, ",");
        return handler;
    }

    addString(s: string) {
        let storageString = localStorage.getItem(this._StorageName);
        let storageArray: string[];
        if (storageString) {
            storageArray = storageString.split(this._Separator);
        } else {
            storageArray = [];
        }
        storageArray.push(s);
        localStorage.setItem(this._StorageName, storageArray.join(this._Separator));
        //snackbar({ severity: "success", message: T("StringAddedToPrint") })
    }
    
    removeAllStrings() {
        localStorage.removeItem(this._StorageName);
    }
    
    removeString(s: string) {
        let storageString = localStorage.getItem(this._StorageName);
        if (!storageString)
            return;
        let storageArray = storageString.split(this._Separator);
        const sIndex = storageArray.indexOf(s);
        if (sIndex < 0)
            return;
        storageArray.splice(sIndex, 1);
        localStorage.setItem(this._StorageName, storageArray.join(this._Separator));
    }
    
    getStrings(): string[] {
        let storageString = localStorage.getItem(this._StorageName);
        if (!storageString)
            return [];
        return storageString.split(this._Separator);
    }
}