import { useContext, useEffect, useMemo } from 'react';
import { AddBox, FactCheck, FeaturedPlayList, Receipt, Science } from '@mui/icons-material';
import { blue, brown, green, orange, purple } from '@mui/material/colors';
import Dashboard from '../components/home/Dashboard';
import DashboardButton from '../components/home/DashboardButton';
import { T } from '../utils/translate/translator';
import DashboardDto from '../dtos/DashboardDto';
import { fetchGet, hasRole, LayoutContext } from 'wistron-layout';
import HideableDashboardButton from '../components/home/HideableDashboardButton';
import { useQuery } from 'react-query';
import { requestUrls } from '../utils/BaseUrl';
import { UserRoles } from '../utils/UserRoles';

export default function Home() {
    const layout = useContext(LayoutContext);

    useEffect(() => {
        layout.setTitle(T("ChemicalControl"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userLogged = useMemo(() => (!!layout.user.name), [layout.user]);


    const { data: dashboardData, refetch } = useQuery<DashboardDto>("chemicalDetail",
        ({ signal }) => fetchGet(requestUrls.dashboard, signal),
        {
            retry: false,
        });

    useEffect(() => {
        refetch();
    }, [userLogged, refetch]);

    return (
        <Dashboard>
            <HideableDashboardButton headerText={T("NewApplication")}
                icon={<AddBox />} iconColor={purple[300]} href="/new_application" hidden={!userLogged} />
            <DashboardButton headerText={T("ApprovedChemicalsInWcz")} dataText={dashboardData?.approvedChemicals}
                icon={<Science />} iconColor={green[300]} href="/chemical/list" />
            <HideableDashboardButton headerText={T("ApplicationsForMe")} dataText={dashboardData?.forMeApplications}
                icon={<FactCheck />} iconColor={orange[600]} href="/application/list/ApplicationsForMe" hidden={!userLogged} />
            <HideableDashboardButton headerText={T("MyApplications")} dataText={dashboardData?.myApplications}
                icon={<FeaturedPlayList />} iconColor={brown[600]} href="/application/list/MyApplications" hidden={!userLogged} />
            <HideableDashboardButton headerText={T("OrderList")} dataText={dashboardData?.orders}
                icon={<Receipt />} iconColor={blue[600]} href="/application/list/OrderList" hidden={!(userLogged && hasRole([UserRoles.CSR, UserRoles.BUYER]))} />
        </Dashboard>
    );
}