import { ExtendedAutocompleteModel } from "./ExtendedAutocomplete";


interface EAModelDisplayProps {
    value: ExtendedAutocompleteModel[],
    className?: string
}

export default function EAModelDisplay(props: EAModelDisplayProps) {

    return (<>
        {props.value.map(model => {
            let i = 0;
            const replacer = () => {
                return model.params[i++] ?? "...";
            };
            return <div className={props.className} key={model.shortText}>
                <b>{model.shortText}</b> - {model.fullText.replaceAll("...", replacer)}
            </div>;
        })}
    </>);
}