import React from "react";
import { Autocomplete, Checkbox, Input, Select, SelectChangeEvent } from "@mui/material";

export type ValidationRule = "required" | ["maxLength", number]

export type ValidationRules = {
    required?: boolean,
    maxLength?: number
}

export function useFormControls<T>(
    state: [T, React.Dispatch<React.SetStateAction<T>>],
    validationRules?: { [Property in keyof T]: ValidationRule[] }
) {

    const [values, setValues] = state;

    const setValue = (name: string, value: any) => {
        setValues((previousState: T) => {
            return {
                ...previousState,
                [name]: value
            };
        });
    };

    const isValid: any = () => {
        if (!validationRules)
            return true;

        return Object.entries<ValidationRule[]>(validationRules).every(([key, rules]) => {
            return Object.values(rules).every((rule) => {
                switch (rule) {
                    case "required":
                        const value = !values[key as keyof T];
                        return !value;

                    default:
                        return true;
                }
            });
        });
    };

    const handleInputAndSelect = (e: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target as (HTMLInputElement | HTMLTextAreaElement);

        let processedValue: string | number = value;

        if (type === 'number') {
            processedValue = Number(value);
        }

        setValue(name, processedValue);
    };

    const handleCheckboxAndAutocomplete = (e: any, value: any) => {
        setValue(e.target.name, value);
    };

    const getHandleFieldChange = (inputTag: any) => {
        if (inputTag in [Input, Select])
            return handleInputAndSelect;

        else if (inputTag in [Checkbox, Autocomplete])
            return handleCheckboxAndAutocomplete;

        return handleInputAndSelect;
    };

    return {
        getHandleFieldChange,
        isValid
    };
}