import React, { useState, useMemo } from "react";
import { Box, Menu, MenuItem, PopoverPosition } from "@mui/material";
import ChemicalLabelDto from "../../dtos/ChemicalLabelDto";
import { LabelStyle } from "../../pages/LabelPrinting";
import { T } from "../../utils/translate/translator";
import EAModelDisplay from "../my_form/extended_autocomplete/EAModelDisplay";
import styles from './LabelsToPrint.module.css';
import LocalStorageHandler from "../../utils/LocalStorageHandler";

interface LabelsToPrintProps {
    labels: ChemicalLabelDto[],
    setLabels: React.Dispatch<React.SetStateAction<ChemicalLabelDto[]>>,
    labelStyle: LabelStyle
}

export const LabelsToPrint = React.forwardRef((props: LabelsToPrintProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const constainerStyle = useMemo(() => {
        return props.labelStyle === "Big" ? styles.big : styles.small;
    }, [props.labelStyle]);

    const [anchorPosition, setAnchorPosition] = useState<null | PopoverPosition>(null);
    const [indexForMenu, setIndexForMenu] = useState<null | number>(null);
    
    const storageHandler = LocalStorageHandler.getHandler("toPrintLabels");
    
    const getIdByIndex = (index: number): string => {
        return props.labels[index].id;
    };

    const openContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorPosition({ left: e.clientX, top: e.clientY });
        setIndexForMenu(parseInt(e.currentTarget.id));
    };

    const closeMenu = () => {
        setAnchorPosition(null);
        setIndexForMenu(null);
    };

    const deleteItem = () => {
        if (indexForMenu === null)
            return;
        const idToDelete = getIdByIndex(indexForMenu);
        storageHandler.removeString(idToDelete);
        props.setLabels(current => {
            let c = [...current];
            c.splice(indexForMenu, 1);
            return c;
        });
        closeMenu();
    };

    const duplicateItem = () => {
        if (indexForMenu === null)
            return;
        const idToAdd = getIdByIndex(indexForMenu);
        storageHandler.addString(idToAdd);
        props.setLabels(current => {
            let c = [...current];
            const toAdd = c.find(l => l?.id === idToAdd)!;
            c.push(toAdd);
            return c;
        });
        closeMenu();
    };

    return (
        <div
            ref={ref}
            className={[styles.containerDiv, constainerStyle].join(" ")}
        >
            <Menu
                open={Boolean(anchorPosition)}
                onClose={closeMenu}
                anchorReference={"anchorPosition"}
                anchorPosition={anchorPosition ?? undefined}
            >
                <MenuItem onClick={deleteItem}>{T("Delete")}</MenuItem>
                <MenuItem onClick={duplicateItem}>{T("Duplicate")}</MenuItem>
            </Menu>
            {props.labels.map((label, i) => {
                if (!label)
                    return null;
                return (<Box className={styles.item} id={i.toString()} onContextMenu={openContextMenu} key={label.id}>
                    <h3>{label.name}</h3>
                    <div className={styles.ghsSymbols}>
                        {label.ghsSymbol ? label.ghsSymbol.map((symbol) => {
                            const ghsSize = props.labelStyle === "Big" ? 26 : Math.min(23, 60 / label.ghsSymbol.length);
                            return <img
                                key={symbol}
                                alt={symbol + "icon"}
                                src={`/img/${symbol.toLowerCase()}.png`}
                                className={styles.ghsSymbol}
                                style={{
                                    height: ghsSize + "mm",
                                    width: ghsSize + "mm"
                                }}
                            />;
                        }) : null}
                    </div>
                    {/* <AttributeDiv labelTKey="Supplier" value={label.supplier} /> */}
                    {!!label.hsentences && label.hsentences.length > 0 ?
                        <>
                            <div className={styles.categoryLabel}>{T("Hsentences+")}</div>
                            <EAModelDisplay value={label.hsentences} />
                        </>
                        : null}
                    {!!label.psentences && label.psentences.length > 0 ?
                        <>
                            <div className={[styles.categoryLabel, styles.psentences].join(" ")} style={{ marginTop: "0.8em" }}>{T("Psentences+")}</div>
                            <EAModelDisplay className={styles.psentences} value={label.psentences} />
                        </>
                        : null}
                </Box>);
            })}
        </div>);
});