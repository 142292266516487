import { ReactElement } from 'react';
import DashboardButton from './DashboardButton';

interface HideableButtonProps {
    headerText?: string | undefined,
    dataText?: string | number | undefined,
    footerText?: string | undefined,
    href: string,
    iconColor: string,
    icon: ReactElement,
    hidden?: boolean
}

export default function HideableDashboardButton(props: HideableButtonProps) {
    if (props.hidden) {
        return null;
    }

    return <DashboardButton {...props} />;
}