export const en = new Map<string, string>();

en.set("Continue?", "Are you sure you want to continue?");
en.set("NoDataToDisplay", "No data to display");
en.set("YesNo-true", "Yes");
en.set("YesNo-false", "No");
en.set("NoPagePermission", "You don't have a permission to view this page");

en.set("Chemical", "Chemical");
en.set("Home", "Home");
en.set("ChemicalControl", "Chemical Control System");
en.set("ChemicalList", "Chemical list");
en.set("ChemicalAssignRequest", "Chemical assign request");
en.set("NewChemicalRequest", "New chemical request");
en.set("AssignRequests", "Assign requests");
en.set("AddRequests", "Add requests");
en.set("PickUpReady", "Ready for pick up");
en.set("ChemicalOrdering", "Chemical ordering");
en.set("AwaitingDelivery", "Awaiting delivery");
en.set("QrScan", "QR Scan");
en.set("ChemicalDetail", "Chemical detail");
en.set("ChemicalEdit", "Edit chemical");
en.set("LocationRelations", "Used on locations");
en.set("ChemicalRelations", "Chemicals on location");
en.set("AddNewChemical", "Add new chemical");
en.set("AddNewLocation", "Add new location");

en.set("Id", "ID");
en.set("Name", "Name");
en.set("NameInputExact", "Name - exactly as stated in MSDS");
en.set("Bl", "BL");
en.set("SupplierBl", "Supplier BL");
en.set("State", "State");
en.set("Density", "Density [g/cm\u00B3]");
en.set("IsDangerous", "Dangerous substance");
en.set("Categories", "Categories");
en.set("Cas", "CAS");
en.set("MinInTonnes", "Minimum [t]");
en.set("MaxInTonnes", "Maximum [t]");
en.set("CrashPlanDangerous", "Crash plan according to law č.254/2001Sb. Dangerous ");
en.set("CrashPlanExtraDangerous", "Crash plan according to law č.254/2001Sb. Extra dangerous substance ");
en.set("ForbiddenForPregnant", "Restrictions for pregnant, nursing employees");
en.set("ForbiddenForYouthful", "Restrictions for youthful employees");
en.set("KhsRulesRequired", "Rules approved by KHS required for work");
en.set("ListedStationarySource", "Listed stationary source law č.201/2012 Sb. (Bod 9.6) 2");
en.set("Location", "Location");
en.set("ResponsiblePerson", "Responsible Person");
en.set("Quantity", "Quantity");
en.set("QuantityUnit", "Quantity unit");
en.set("Consumption", "Consumption");
en.set("IsApproved", "Approved");
en.set("IsActive", "Active");

en.set("NewChemicalRequestCreated", "New chemical request successfully created");
en.set("AssignChemicalRequestCreated", "Assign chemical request successfully created");
en.set("Approve", "Approve");
en.set("Remove", "Remove");
en.set("Delete", "Delete");
en.set("NotifyArrival", "Notify arrival");
en.set("NotifyPickUp", "Notify pick up");
en.set("ChemicalKind", "Chemical - kind");
en.set("Approved", "Approved");
en.set("ArrivalNotified", "Arrival notified");
en.set("PickUpNotified", "Pick up notified");
en.set("OrderDetail", "Order detail");
en.set("OrderRequests", "Order requests");
en.set("ChemicalKinds", "Chemical - kinds");
en.set("RequestAssignChemical", "Request assignment of chemical");
en.set("OrderList", "Order list");
en.set("AwaitingApproval", "Awaiting approval");
en.set("Ordered", "Ordered");
en.set("Finished", "Finished");
en.set("RequestOrderOfChemical", "Order chemical request");
en.set("NewRequest", "New request");

en.set("Status", "Status");
en.set("ChemicalName", "Chemical name");
en.set("Description", "Description");
en.set("Department", "Department");
en.set("New", "New");
en.set("New(St.Rod)", "New");
en.set("New(Z.Rod)", "New");
en.set("SelectedChemical", "Selected chemical");
en.set("RequestingNewChemical", "Requesting new chemical");
en.set("ChemicalSelected", "Chemical selected");
en.set("RequestingNewLocation", "Requesting new location");
en.set("AssignedLocationSelected", "Assigned location selected");
en.set("UnassignedLocationSelected", "Unassigned location selected");
en.set("Order", "Order");
en.set("Amount", "Amount");
en.set("Pic", "PIC");
en.set("PicDepartment", "PIC Department");
en.set("Record", "Record");
en.set("LocationList", "Locations");
en.set("ApprovedChemicalsInWcz", "Approved chemicals");

// Common
en.set("Yes", "Yes");
en.set("No", "No");
en.set("CreatedDate", "Created at");
en.set("Detail", "Detail");
en.set("Reject", "Reject");
en.set("Confirm", "Confirm");
en.set("SaveEdit", "Save changes");
en.set("deleted", "deleted");
en.set("fromLocation", "from location");
en.set("Control", "Control");
en.set("Add", "Add");
en.set("Edit", "Edit");
en.set("Save", "Save");
en.set("CancelEditing", "Cancel editing");
en.set("ChangesSaved", "Changes saved");

// Dashboard
en.set("NewApplication", "New application for\u00a0chemical");
en.set("NewChemical", "New chemical");
en.set("Applications", "Applications");
en.set("MyApplications", "My applications");
en.set("ApplicationsForMe", "Waiting for my approval");
en.set("QrPrinting", "QR printing");
en.set("LabelPrinting", "Label printing");

// New application
en.set("Unit", "Unit");
en.set("SendApplication", "Send application");
en.set("ChooseChemical", "Choose chemical");
en.set("ChooseLocation", "Choose location");
en.set("DisplayDetails", "Display details");
en.set("HideDetails", "Hide details");
en.set("PleaseSelect", "Please select");
en.set("AChemical", "a chemical");
en.set("ALocation", "a location");
en.set("FillCorrectly", "Please fill the form correctly");
en.set("ToContinue", "to continue");
en.set("Link", "Link");
en.set("PackagingQuantity", "Amount of packagings");
en.set("PackagingLink", "Link to packaging");
en.set("TotalAmount", "Total amount");
en.set("LastChanged", "Last changed");
en.set("UpdateLink", "Update link");
en.set("Now", "Now");
en.set("Cancel", "Cancel");
en.set("InvalidLink", "Invalid link format");
en.set("ChosenFiles", "Chosen files:");
en.set("UploadedFiles", "Uploaded files:");
en.set("OrderWhenCreating", "Create an order");
en.set("Upload", "Upload");
en.set("CreateChemical", "Add chemical");
en.set("NewLocation", "Add location");

//Chemical
en.set("BlNumber", "MSDS Number");
en.set("BlPublicationDate", "MSDS publication year");
en.set("Supplier", "Supplier");
en.set("PackingQuantity", "Packing Quantity");
en.set("PackingUnit", "Packing Unit");
en.set("CasNumber", "CAS Number");
en.set("Hsentences", "H + EUH Sentences");
en.set("Psentences", "P Sentences");
en.set("Flashpoint", "Flash-point");
en.set("FlashpointUnit", "Flash-point (in °C)");
en.set("LowBoiling", " (low boiling)");
en.set("HazardClass", "Hazard class");
en.set("GhsSymbol", "GHS Danger Symbols");
en.set("SvhcSubstance", "SVHC Substance");
en.set("DangerousSubstanceCategories", "Category of dangerousness");
en.set("FlammableLiquids", "P5C Flammable liquids category 2 or 3");
en.set("FlammableLiquidsAmount", "P5C Flammable liquids category 2 or 3 - quantity");
en.set("FlammableAerosol", "P3a Flammable aerosols");
en.set("FlammableAerosolAmount", "P3a Flammable aerosols - quantity");
en.set("FlammableGases", "P2 Flammable gases");
en.set("FlammableGasesAmount", "P2 Flammable gases - quantity");
en.set("AquaticHazardE1", "E1 Aquatic hazard");
en.set("AquaticHazardE1Amount", "E1 Aquatic hazard - quantity");
en.set("AquaticHazardE2", "E2 Aquatic hazard");
en.set("AquaticHazardE2Amount", "E2 Aquatic hazard - quantity");
en.set("DangerousByWaterLaw", "Hazardous substance - Emergency plan acc. to the Water Act No. 254/2001 Coll.");
en.set("ExtraDangerousByWaterLaw", "Particularly dangerous substance - Emergency plan acc. to the Water Act No. 254/2001 Coll.");
en.set("RestrictedForPregnants", "Prohibition/restrictions for pregnant employees");
en.set("RestrictedForYouthful", "Prohibition/restrictions for adolescent employees");
en.set("KhsRulesRecommended", "KHS Rules Recommended");
en.set("Voc", "VOC (in%)");
en.set("Packaging", "Packaging");
en.set("LocationsNames", "Locations names");
en.set("FirstAid", "First Aid");
en.set("FirstAidGeneral", "First aid general");
en.set("FirstAidInhalation", "First aid inhalation");
en.set("FirstAidSkinContact", "First aid skin contact");
en.set("FirstAidEyeContact", "First aid eye contact");
en.set("FirstAidIngestion", "First aid ingestion");
en.set("FAGeneral", "General");
en.set("FAInhalation", "Inhalation");
en.set("FASkinContact", "Skin contact");
en.set("FAEyeContact", "Eye contact");
en.set("FAIngestion", "Ingestion");
en.set("FireExtinguishers", "Fire Extinguishers");
en.set("FireExtinguisherSuitable", "Suitable fire extinguishers");
en.set("FireExtinguisherNonSuitable", "Unsuitable fire extinguishers");
en.set("BodyProtection", "Body Protection");
en.set("ProtectionAirways", "Airways protection");
en.set("ProtectionHands", "Hand protection");
en.set("ProtectionEyes", "Eyes protection");
en.set("ProtectionSkinAndBody", "Skin and body protection");
en.set("DisposalConsiderations", "Disposal Considerations");
en.set("WasteTypeCode", "Waste type code");
en.set("WasteType", "Waste type");
en.set("WasteSubgroup", "Waste subgroup");
en.set("WasteGroup", "Waste group");
en.set("PhysicalChemicalAttributes", "Physical and chemical attributes");
en.set("Others", "Others");
en.set("PhraseType", "Phrase type");
en.set("PhraseName", "Phrase name");
en.set("FullText", "Description");
en.set("ParamsInfo", "Additional info");
en.set("CreatePhrase", "Create phrase");
en.set("PhraseExample", "e.g. P123");
en.set("PhraseCreated", "Phrase created");
en.set("ManipulationAndStorage", "Manipulation and storage considerations");
en.set("Manipulation", "Manipulation");
en.set("Storage", "Storage");

// Application
en.set("WaitingFor", "Waiting for");
en.set("Requestor", "Requestor");
en.set("RequestorEmployeeName", "Requestor name");
en.set("PicEmployeeName", "PIC name");
en.set("ApplicationDetail", "Application detail");
en.set("LocationName", "Location name");
en.set("Notice", "Notice");
en.set("Notice/Reason", "Notice/Reason");
en.set("WaitingStatus", "Status");
en.set("Withdraw", "Withdraw");
en.set("WaitingForCsr", "Waiting for CSR");
en.set("WaitingForLocationPic", "Waiting for location PIC");
en.set("WaitingForOrder", "Waiting for order");
en.set("NewApplicationCreated", "Application successfully sent");
en.set("NoBlFound", "No MSDS uploaded");
en.set("BlFile", "Material Safety Data Sheet");
en.set("ToStatus", "New Status");
en.set("FromStatus", "Old Status");
en.set("Approver", "Approver");
en.set("RequestHistory", "Request history");
en.set("Resend", "Resend");
en.set("InternalOrderNumber", "Internal order number");
en.set("AtLocation", "At location");
en.set("Reception", "Reception");
en.set("Warehouse", "Warehouse");
en.set("PickedUpBy", "Picked up by");
en.set("ReceivedAt", "Received at");
en.set("NoOrderRelated", "Order was not created.");
en.set("ChemicalCreated", "Chemical successfully created");
en.set("LocationCreated", "Location successfully created");
// Application statuses
en.set("WAITING_FOR_LOCATION_PIC_APPROVAL", "Waiting for location PIC approval");
en.set("WAITING_FOR_CSR_APPROVAL", "Waiting for CSR approval");
en.set("WAITING_FOR_ORDER", "Waiting to be ordered by buyer");
en.set("WAITING_FOR_DELIVERY", "Waiting for delivery");
en.set("WAITING_FOR_CSR_RECEIVE", "Waiting for CSR to finish receiving");
en.set("READY_FOR_PICKUP", "Ready for pick up");
en.set("PICKED_UP", "Picked up");
en.set("STORED", "Stored");
en.set("WITHDRAWN_BY_USER", "Withdrawn by user");
en.set("REJECTED", "Rejected");
en.set("DONE", "Done");
// Application history
en.set("MadeAt", "Made At");
en.set("ByRole", "Approver role");
en.set("Action", "Action");

// CAS Administration
en.set("Administration", "Administration");
en.set("AllSvhcDangerousCasNumbers", "All SVHC dangerous CAS numbers");
en.set("SaveSvhcDangerousCasNumbers", "Save SVHC dangerous CAS numbers");
en.set("AddSvhcDangerousCasNumbers", "Add SVHC dangerous CAS number");
en.set("AddCasNumber", "Add CAS number");
en.set("SvhcCasNumbersSaved", "SVHC CAS numbers saved");

// New chemical form
en.set("BlFileTooBig", "MSDS file is too big. Max size is &0.");
en.set("BlWrongType", "MSDS file has to be of pdf or msword type.");

// Purchase history
en.set("PurchaseHistory", "Order history");
en.set("AmountOrdered", "Amount ordered");
en.set("OrderedAt", "Ordered at");
en.set("RequestorName", "Orderer name");

// QR printing
en.set("QrAddedToPrint", "QR added to print queue");
en.set("Print", "Print");
en.set("QrCodes", "QR codes");
en.set("Labels", "Labels");
en.set("DeleteAll", "Delete all &0");
en.set("ConfirmDeleteAll", "Delete all &0 codes from printing?");
en.set("QrPrintingTooltip", "To remove QR code, click on it to select it then press Delete.");
en.set("Big", "Big");
en.set("Small", "Malé");
en.set("LabelStyle", "Label style");
en.set("Hsentences+", "Hazard statements");
en.set("Psentences+", "Precautionary statements");
en.set("AddLabelToPrint", "Add label to printing");
en.set("LabelAddedToPrint", "Label added to printing");
en.set("Duplicate", "Duplicate");