import { useContext, useState } from 'react';
import { Alert, Box, Button } from '@mui/material';
import { NewApplicationContext } from '../../utils/NewApplicationContext';
import { T } from '../../utils/translate/translator';
import ChemicalSection from './ChemicalSection';
import { useMutation } from 'react-query';
import NewRequestDto from '../../dtos/NewRequestDto';
import { fetchPost, getToken, LayoutContext } from 'wistron-layout';
import { chemicalUrls, fileApiUrl } from '../../utils/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { Upload } from 'tus-js-client';
import packageJson from '../../../package.json';
import NewChemicalModel from '../../models/NewChemicalModel';

export default function CreateChemical() {
    const { snackbar } = useContext(LayoutContext);
    const newApplicationContext = useContext(NewApplicationContext);
    const navigate = useNavigate();

    const [blFileState, setBlFileState] = useState<File[]>([]);

    const uploadSuccessful = () => {
        snackbar({ message: T("ChemicalCreated"), severity: 'success' });
        navigate("/");
    };

    const uploadBlFiles = async (requestId: string) => {
        const files = blFileState;
        if (files.length === 0) {
            alert("No files selected.");
            return;
        }
        const uploadPromises = files.map(async (file) => {
            const upload = new Upload(file, {
                endpoint: fileApiUrl + "/v1/upload",
                chunkSize: 1048576, //1MB
                metadata: {
                    appName: packageJson.name,
                    subId: requestId,
                    filename: file.name,
                    filetype: file.type
                },
                headers: { "Authorization": `Bearer ${await getToken()}` },
                onError: (error) => {
                    if (error instanceof Error) {
                        snackbar({ message: error.message ?? "", severity: 'error' });
                    }
                },
                onSuccess: uploadSuccessful
            });
            return upload.start();
        });
        await Promise.all(uploadPromises);
        setBlFileState([]);
    };

    const { mutate: send } = useMutation((applicationDto: NewRequestDto) =>
        fetchPost(chemicalUrls.newChemical, applicationDto),
        {
            onSuccess: (response: { id: string }) => {
                if (newApplicationContext.chemical.selectedIdState[0] === "new")
                    uploadBlFiles(response.id);
                else
                    uploadSuccessful();
            }
        }
    );

    const handleSendClick = () => {
        const chemicalPart = newApplicationContext.chemical.selectedIdState[0] === "new"
            ? { chemical: newApplicationContext.chemical.modelState[0] as NewChemicalModel }
            : { chemicalId: newApplicationContext.chemical.selectedIdState[0] };

        send({...chemicalPart});
    };

    return (
        <Box sx={{ margin: { xs: "0em auto", md: "1em auto" }, padding: "1em", maxWidth: "800px" }}>
            <ChemicalSection blFileState={blFileState} setBlFileState={setBlFileState} createChemical />
            {newApplicationContext.chemical.isFormValidState[0]
                ? <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button sx={{ display: "block" }} onClick={handleSendClick}>{T("CreateChemical")}</Button>
                </Box>
                : <Alert className="section-paper" severity="warning">{T("FillCorrectly") + " " + T("ToContinue")}</Alert>}

        </Box>
    );
}