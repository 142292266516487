import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface BoxMinusHeaderProps {
    children: ReactNode
}

export default function BoxMinusHeader(props: BoxMinusHeaderProps) {
    return (
        <Box 
            sx={{
                height: {
                    xs: "calc(100vh - 56px)",
                    sm: "calc(100vh - 64px)"
                }
            }}>
            {props.children}
        </Box>
    );
}