import React, { useContext, useState } from 'react';
import { Box, FormControl, InputLabel, Input, Button, Divider, Select, MenuItem } from '@mui/material';
import ApplicationDetailDto from '../../../dtos/ApplicationDetailDto';
import { T } from '../../../utils/translate/translator';
import HideableContent from '../../common/HideableContent/HideableContent';
import ChemicalForm from '../../new_application/ChemicalForm';
import LocationForm from '../../new_application/LocationForm';
import ChemicalDetail from './ChemicalDetail';
import LocationDetail from './LocationDetail';
import OrderDetail from './OrderDetail';
import NewLocationModel from '../../../models/NewLocationModel';
import { useMutation } from 'react-query';
import { fetchPut, getToken, hasRole, LayoutContext } from 'wistron-layout';
import { requestUrls } from '../../../utils/BaseUrl';
import UpdateRequestDto from '../../../dtos/UpdateRequestDto';
import RequestRejectDto from '../../../dtos/RequestRejectDto';
import FileLink from '../../file/FileLink';
import { UserRoles } from '../../../utils/UserRoles';
import NewChemicalModel from '../../../models/NewChemicalModel';
import PackagingDto from '../../../dtos/PackagingDto';
import { Upload, Viewer } from 'wistron-files';
import PersonAutocomplete, { PersonAutocompleteModel } from '../../common/PersonAutocomplete';

interface ApplicationDetailDynamicProps {
    applicationModel: ApplicationDetailDto,
    refetchModel: () => void
}

export default function ApplicationDetailDynamic(props: ApplicationDetailDynamicProps) {
    const { refetchModel } = props;
    const { user } = useContext(LayoutContext);

    const [applicationModel, setApplicationModel] = useState(props.applicationModel);
    const waitingStatus = applicationModel.waitingStatus;

    const chemicalState = useState(applicationModel.chemical);

    const isChemicalValidState = useState(false);
    const chemicalIdState = useState("new");
    const locationState = useState<NewLocationModel>({
        name: applicationModel.location.name,
        pic: {
            id: applicationModel.location.picId,
            label: applicationModel.location.picName
        }
    });
    const isLocationValidState = useState(false);
    const locationIdState = useState("new");
    const packagingState = useState<PackagingDto>(applicationModel.packaging);
    const [notice, setNotice] = useState(applicationModel.notice);

    const { mutate: reject } = useMutation((dto: RequestRejectDto) =>
        fetchPut(requestUrls.reject, dto),
        {
            onSuccess: refetchModel
        });

    const { mutate: withdraw } = useMutation((dto: RequestRejectDto) =>
        fetchPut(requestUrls.withdraw, dto),
        {
            onSuccess: refetchModel
        });

    const handleCancel = (cancelType: "withdraw" | "reject") => {
        if (!notice) {
            alert("Please, specify the reason for " + cancelType);
            return;
        }
        const toSendDto = {
            id: applicationModel.id,
            notice: notice
        };
        if (cancelType === "reject")
            reject(toSendDto);
        if (cancelType === "withdraw")
            withdraw(toSendDto);
    };

    const { mutate: approve } = useMutation((applicationDto: UpdateRequestDto) =>
        fetchPut(requestUrls.approve, applicationDto),
        {
            onSuccess: () => {
                refetchModel();
            }
        }
    );

    const { mutate: edit } = useMutation((applicationDto: UpdateRequestDto) =>
        fetchPut(requestUrls.edit, applicationDto),
        {
            onSuccess: () => {
                refetchModel();
            }
        }
    );

    const handleApprove = () => {
        const toSendChemical = chemicalState[0];
        delete toSendChemical.svhcSubstance;
        approve({
            chemical: toSendChemical,
            location: {
                name: locationState[0].name,
                picId: locationState[0].pic.id,
                picName: locationState[0].pic.label
            },
            packaging: packagingState[0],
            notice: notice,
            id: applicationModel.id,
            receivedAt: applicationModel.receivedAt,
            pickedUpByName: applicationModel.pickedUpBy?.label,
            pickedUpById: applicationModel.pickedUpBy?.id
        });
    };

    const handleEdit = () => {
        const toSendChemical = chemicalState[0];
        delete toSendChemical.svhcSubstance;
        edit({
            chemical: toSendChemical,
            location: {
                name: locationState[0].name,
                picId: locationState[0].pic.id,
                picName: locationState[0].pic.label
            },
            packaging: packagingState[0],
            notice: notice,
            id: applicationModel.id
        });
    };

    const setPickedUpByPerson = (person: PersonAutocompleteModel | undefined) => {
        setApplicationModel((previous) => ({
            ...previous,
            pickedUpBy: person
        }));
    };

    const editButton = <Button onClick={() => handleEdit()}>
        {waitingStatus === "REJECTED" ? T("Resend") : T("SaveEdit")}
    </Button>;

    const ownerButtons = <>
        <Button color="error" onClick={() => handleCancel("withdraw")}>{T("Withdraw")}</Button>
    </>;
    const stepApproverButtons = <>
        <Button color="error" onClick={() => handleCancel("reject")}>{T("Reject")}</Button>
        {hasRole([UserRoles.CSR]) ? editButton : null}
        <Button onClick={() => handleApprove()}>{T("Approve")}</Button>
    </>;

    const isOwnerAndEditable = (): boolean => {
        return user.employeeId.toLowerCase() === applicationModel.requestorEmployeeId.toLowerCase()
            && (waitingStatus === "WAITING_FOR_CSR_APPROVAL"
                || waitingStatus === "WAITING_FOR_LOCATION_PIC_APPROVAL"
                || waitingStatus === "REJECTED");
    };

    const isStepApprover = (): boolean => {
        return (waitingStatus === "WAITING_FOR_CSR_APPROVAL" && hasRole([UserRoles.CSR]))
            || (waitingStatus === "WAITING_FOR_LOCATION_PIC_APPROVAL" && locationState[0].pic.id === user.employeeId);
    };

    const isOrdered = (): boolean => {
        return !!applicationModel.internalOrderNumber;
    };

    const isStepConfirmer = (): boolean => {
        return (waitingStatus === "WAITING_FOR_ORDER" && hasRole([UserRoles.BUYER]))
            || (waitingStatus === "WAITING_FOR_DELIVERY"
                && (hasRole([UserRoles.RECEPTION])
                    || hasRole([UserRoles.WAREHOUSE])
                    || hasRole([UserRoles.BUYER])
                    || hasRole([UserRoles.CSR])
                ))
            || (waitingStatus === "WAITING_FOR_CSR_RECEIVE" && hasRole([UserRoles.CSR]))
            || (waitingStatus === "READY_FOR_PICKUP" && hasRole([UserRoles.RECEPTION, UserRoles.WAREHOUSE]))
            || (waitingStatus === "PICKED_UP" && user.employeeId === applicationModel.pickedUpBy?.id);
    };

    let chemicalPart = <ChemicalDetail chemical={chemicalState[0]} application />;
    let locationPart = <LocationDetail location={applicationModel.location} />;

    let orderPart = <OrderDetail packaging={applicationModel.packaging} quantity={applicationModel.quantity} />;
    let controlPart = null;
    let filesPart = null;

    if (hasRole([UserRoles.BUYER]) && isOrdered()) {
        filesPart = <>
            <Viewer getBearerToken={getToken} subId={applicationModel.id} />
            <Upload getBearerToken={getToken} subId={applicationModel.id} />
        </>;
    } else if (hasRole([UserRoles.CSR]) && isOrdered()) {
        filesPart = <Viewer getBearerToken={getToken} subId={applicationModel.id} />;
    }

    if (isOwnerAndEditable() || isStepApprover()) {
        if (applicationModel.isChemicalApproved === false) {
            chemicalPart = <ChemicalForm chemical={{
                modelState: chemicalState as [NewChemicalModel, React.Dispatch<React.SetStateAction<NewChemicalModel>>],
                isFormValidState: isChemicalValidState,
                selectedIdState: chemicalIdState
            }} blFileState={[]} setBlFileState={() => []} application />;
        }
        if (applicationModel.isLocationApproved === false) {
            locationPart = <LocationForm location={{
                modelState: locationState,
                isFormValidState: isLocationValidState,
                selectedIdState: locationIdState
            }} />;
        }
        controlPart = <Box>
            <FormControl fullWidth>
                <InputLabel shrink>{T("Notice/Reason")}</InputLabel>
                <Input value={notice} onChange={(e) => { setNotice(e.target.value); }} />
            </FormControl>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                {isStepApprover()
                    ? stepApproverButtons : null}
                {isOwnerAndEditable()
                    ? ownerButtons : null}
            </Box>
        </Box>;
    }

    if (isStepConfirmer()) {
        controlPart = <Box>
            <FormControl fullWidth>
                <InputLabel shrink>{T("Notice")}</InputLabel>
                <Input value={notice} onChange={(e) => { setNotice(e.target.value); }} />
            </FormControl>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                {applicationModel.waitingStatus === "WAITING_FOR_DELIVERY"
                    ? <FormControl sx={{ marginTop: "0.5em" }}>
                        <InputLabel>{T("AtLocation")}</InputLabel>
                        <Select
                            onChange={(e) => {
                                setApplicationModel(previous => ({
                                    ...previous,
                                    receivedAt: e.target.value as string
                                }));
                            }}
                            label={T("AtLocation")}
                            sx={{ minWidth: "10em" }}
                        >
                            <MenuItem value="Reception">{T("Reception")}</MenuItem>
                            <MenuItem value="Warehouse">{T("Warehouse")}</MenuItem>
                        </Select>
                    </FormControl>
                    : null
                }
                {applicationModel.waitingStatus === "READY_FOR_PICKUP"
                    ? <PersonAutocomplete
                        labelTKey="PickedUpBy"
                        personState={[applicationModel.pickedUpBy, setPickedUpByPerson]}
                        defaultPersonId={applicationModel.requestorEmployeeId} />
                    : null
                }
                <Button
                    onClick={() => handleApprove()}
                    disabled={(applicationModel.waitingStatus === "WAITING_FOR_DELIVERY" && applicationModel.receivedAt === undefined)
                        || (applicationModel.waitingStatus === "READY_FOR_PICKUP" && applicationModel.pickedUpBy === undefined)}
                >
                    {T("Confirm")}
                </Button>
            </Box>
        </Box>;
    }

    return (<>
        <HideableContent title={T("Chemical")}>
            <Box>
                <span style={{ fontWeight: "bold" }}>{T("BlFile")}:</span>
                <FileLink subId={chemicalState[0].id} big />
            </Box>
            {chemicalPart}
        </HideableContent>
        <HideableContent title={T("Location")}>
            {locationPart}
        </HideableContent>
        <HideableContent title={T("Order")}>
            {orderPart}
        </HideableContent>
        {filesPart !== null
            ? <Divider sx={{ margin: "1.5em 0" }} /> : null}
        {filesPart}
        {controlPart !== null
            ? <Divider sx={{ margin: "1.5em 0" }} /> : null}
        {controlPart}
    </>);
}