
export const ghsSymbols = [
    "GHS01",
    "GHS02",
    "GHS03",
    "GHS04",
    "GHS05",
    "GHS06",
    "GHS07",
    "GHS08",
    "GHS09",
];

export const dangerousSubstanceCategories = [
    "H1 Akutní toxicita kategorie 1",
    "H2 Akutní toxicita kategorie 2, 3",
    "H3 Toxicita pro specifické cílové orgány",
    "P1a Výbušniny",
    "P1b Výbušniny",
    "P2 Hořlavé plyny kategorie 1, 2",
    "P3a Hořlavé aerosoly",
    "P3b Hořlavé aerosoly",
    "P4 Oxidujíci plyny kategorie 1",
    "P5a Hořlavé kapaliny",
    "P5b Hořlavé kapaliny",
    "P5c Hořlavé kapaliny",
    "P6a Samovolně reagující látky a směsi a organické peroxidy",
    "P6b Samovolně reagující látky a směsi a organické peroxidy",
    "P7 Samozápalné kapaliny a tuhé látky",
    "P8 Oxidující kapaliny a tuhé látky",
    "E1 Nebezpečnost pro vodní prostředí v kategorii akutní 1 nebo chronická 1",
    "E2 Nebezpečnost pro vodní prostředí v kategorii chronická 2",
    "O1 Látky nebo směsi se standardní větou o nebezpečnosti EUH014",
    "O2 Látky a směsi, které při styku s vodou uvolňují hořlavé plyny, kategorie 1",
    "O3 Látky nebo směsi se standardní větou o nebezpečnosti EUH029",
];