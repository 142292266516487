import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { T } from '../../utils/translate/translator';

export interface YesNoInputProps {
    value: string,
    setValue: (value: any) => void,
    name: string,
    disabled: boolean,
    required?: boolean
}

export default function YesNoInput(props: YesNoInputProps) {
    const handleChange = (e: SelectChangeEvent<string>) => {
        const value = e.target.value;
        if (!value)
            props.setValue(undefined);
        else
            props.setValue(e.target.value === "true");
    };
    return (
        <Select
            value={props.value === undefined || props.value === null ? undefined : props.value ? "true" : "false"}
            onChange={handleChange}
            variant="standard"
            name={props.name}
            disabled={props.disabled}
            required={props.required}
        >
            <MenuItem value={undefined} key=" ">-</MenuItem>
            <MenuItem value={"true"} key="true">{T("Yes")}</MenuItem>
            <MenuItem value={"false"} key="false">{T("No")}</MenuItem>
        </Select>
    );
}