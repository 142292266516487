import { OpenInNew } from '@mui/icons-material';
import { Link, Typography } from '@mui/material';
import PackagingDto from '../../../dtos/PackagingDto';
import { T } from '../../../utils/translate/translator';
import AttributeDiv from '../../common/AttributeDiv/AttributeDiv';
import styles from "../../common/AttributeDiv/AttributeDiv.module.css";

interface OrderDetailProps {
    packaging: PackagingDto,
    quantity: number
}

export default function OrderDetail(props: OrderDetailProps) {
    if (props.packaging !== null) {
        const amountCheck = props.packaging.amount === null ? "0" : props.packaging.amount;
        const unitCheck = props.packaging.unit === null ? "" : props.packaging.unit;


        return (
            <>
                <AttributeDiv labelTKey="Packaging" value={amountCheck + " " + unitCheck} />
                <div>
                    <span className={styles.label}>{T("PackagingLink")}:</span>&nbsp;
                    <Link href={props.packaging.link} target="_blank" rel="noopener noreferrer">
                        {props.packaging.link} <OpenInNew fontSize={"small"} />
                    </Link>
                </div>
                <AttributeDiv labelTKey="Quantity" value={props.quantity} />
            </>
        );
    } else {
        return (
                <Typography>{T("NoOrderRelated")}</Typography>
        );
    }
}