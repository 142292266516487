import React, { useContext, useEffect, useState } from "react";
import { Box, Button, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchDelete, fetchGet, fetchPut, hasRole, LayoutContext } from "wistron-layout";
import ChemicalDetail from "../../components/application/detail/ChemicalDetail";
import { chemicalLocationRelationUrls, chemicalUrls } from "../../utils/BaseUrl";
import { T } from "../../utils/translate/translator";
import Loading from "../../components/common/Loading";
import ChemicalDetailDto from "../../dtos/ChemicalDetailDto";
import { formatDateTime } from "../../utils/StringUtils";
import { UserRoles } from "../../utils/UserRoles";
import { Delete, MoreHoriz, QrCode2Sharp } from "@mui/icons-material";
import styles from "./ChemicalDetailPage.module.css";
import { default as attributeDivStyles } from '../../components/common/AttributeDiv/AttributeDiv.module.css';
import LocalStorageHandler from "../../utils/LocalStorageHandler";
import ChemicalForm from "../../components/new_application/ChemicalForm";
import NewChemicalModel from "../../models/NewChemicalModel";

type ChemicalDetailParams = {
    id: string
}

export default function ChemicalDetailPage() {
    const layout = useContext(LayoutContext);
    useEffect(() => {
        layout.setBackArrow("/chemical/list");
        layout.setTitle(T("Chemical"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { id } = useParams<ChemicalDetailParams>();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
    const [chemicalDetail, setChemicalDetail] = useState<ChemicalDetailDto | null>(null);
    const chemicalState = useState<NewChemicalModel>({} as NewChemicalModel);
    const isChemicalValidState = useState(false);
    const chemicalIdState = useState("new");
    const navigate = useNavigate();

    const labelStorageHandler = LocalStorageHandler.getHandler("toPrintLabels");

    const dtoToNewModel = (dto: ChemicalDetailDto): NewChemicalModel => {
        const model = JSON.parse(JSON.stringify(dto));
        delete model.purchaseHistory;
        delete model.locationRelations;
        return model;
    };

    const newModelToDto = (model: NewChemicalModel): ChemicalDetailDto => {
        const dto = JSON.parse(JSON.stringify(model));
        dto.purchaseHistory = chemicalDetail?.purchaseHistory;
        dto.locationRelations = chemicalDetail?.locationRelations;
        return dto;
    };

    const { refetch } = useQuery<ChemicalDetailDto, Response>("chemicalDetail",
        ({ signal }) => fetchGet(chemicalUrls.detail + id, signal), {
        onSuccess: (data) => {
            setChemicalDetail(data);
            chemicalState[1](dtoToNewModel(data));
        }
    });

    const { mutate: deleteRelation } = useMutation((id: string) =>
        fetchDelete(chemicalLocationRelationUrls.delete + id),
        {
            onSuccess: () => {
                layout.snackbar({ message: `${T("Chemical")} ${T("deleted")} ${T("fromLocation")}`, severity: 'success' });
            }
        }
    );

    const updateChemical = useMutation((chemical: NewChemicalModel) => fetchPut(`${chemicalUrls.update}/${chemical.id}`, chemical), {
        onSuccess: () => {
            layout.snackbar({ severity: "success", message: T("ChangesSaved") });
            refetch();
        }
    });

    if (!chemicalDetail)
        return <Loading />;

    const { locationRelations, purchaseHistory, ...chemicalData } = chemicalDetail;

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorElement(null);
    };

    const handleAddLabelToPrint = () => {
        labelStorageHandler.addString(chemicalDetail.id);
        layout.snackbar({ severity: "success", message: T("LabelAddedToPrint") });
        closeMenu();
    };

    const handleStartEditing = () => {
        setIsEditing(true);
        closeMenu();
    };

    const handleCancelEditing = () => {
        chemicalState[1](dtoToNewModel(chemicalDetail));
        setIsEditing(false);
        closeMenu();
    };

    const handleSaveEditing = () => {
        setChemicalDetail(newModelToDto(chemicalState[0]));
        updateChemical.mutateAsync(chemicalState[0]);
        setIsEditing(false);
    };

    const link = (locationId: string) => () => {
        navigate(`/location/${locationId}`);
    };

    return (
        <Box sx={{ margin: { xs: "0em auto", md: "1em auto" } }} className={styles.outer}>
            <div className={styles.menuDiv} hidden={!hasRole([UserRoles.CSR])}>
                <IconButton onClick={openMenu}>
                    <MoreHoriz />
                </IconButton>
                <Menu
                    open={Boolean(anchorElement)}
                    onClose={closeMenu}
                    anchorEl={anchorElement}
                >
                    <MenuItem onClick={handleAddLabelToPrint}>{T("AddLabelToPrint")}</MenuItem>
                    {isEditing ? <MenuItem onClick={handleCancelEditing}>{T("CancelEditing")}</MenuItem>
                        : <MenuItem onClick={handleStartEditing}>{T("Edit")}</MenuItem>}
                </Menu>
            </div>
            {isEditing
                ? <Paper className={styles.editPaper}>
                    <ChemicalForm chemical={{
                        modelState: chemicalState,
                        isFormValidState: isChemicalValidState,
                        selectedIdState: chemicalIdState
                    }} blFileState={[]} setBlFileState={() => []} chemicalData={chemicalData} />
                    <div className={styles.editControl}>
                        <Button onClick={handleCancelEditing} color="error">
                            {T("CancelEditing")}
                        </Button>
                        <Button onClick={handleSaveEditing}>
                            {T("Save")}
                        </Button>
                    </div>
                </Paper>
                : <>
                    <ChemicalDetail chemical={chemicalData} showBlFile />
                    {locationRelations && locationRelations.length ?
                        <div className={styles.padded}>
                            <div style={{ margin: "0.7rem auto" }}>
                                <span className={attributeDivStyles.label}>{T("LocationRelations")}:</span>
                            </div>
                            <Table>
                                <TableHead>
                                    <TableCell>{T("LocationName")}</TableCell>
                                    {hasRole([UserRoles.CSR]) ? <TableCell>{T("Control")}</TableCell> : null}
                                </TableHead>
                                <TableBody>
                                    {locationRelations.map(l => <TableRow key={l.relationId}>
                                        <TableCell><Button variant="text" onClick={link(l.locationId)}>{l.locationName}</Button></TableCell>
                                        {hasRole([UserRoles.CSR])
                                            ? <TableCell>
                                                <IconButton onClick={() => deleteRelation(l.relationId)}>
                                                    <Delete />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        LocalStorageHandler.getHandler("toPrintQrCodes").addString(l.relationId);
                                                        layout.snackbar({ severity: "success", message: T("QrAddedToPrint") });
                                                    }}
                                                >
                                                    <QrCode2Sharp />
                                                </IconButton>
                                            </TableCell>
                                            : null}
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </div>
                        : null}
                    {purchaseHistory.length > 0 && (purchaseHistory[0].amountOrdered !== null && purchaseHistory.length !== 1) ?
                        <div className={styles.padded}>
                            <div style={{ margin: "1rem auto" }}>
                                <span style={{ fontWeight: "bold" }}>{T("PurchaseHistory")}:</span>
                            </div>
                            <Table>
                                <TableHead sx={{ fontWeight: "bold" }}>
                                    <TableRow>
                                        <TableCell>{T("InternalOrderNumber")}</TableCell>
                                        <TableCell>{T("LocationName")}</TableCell>
                                        <TableCell>{T("AmountOrdered")}</TableCell>
                                        <TableCell>{T("OrderedAt")}</TableCell>
                                        <TableCell>{T("RequestorName")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {purchaseHistory.map(p => p.amountOrdered === null ? "" : <TableRow key={p.orderedAt + p.requestorName}>
                                        <TableCell><Link to={"/application/" + p.id} >{p.internalOrderNumber}</Link></TableCell>
                                        <TableCell>{p.locationName}</TableCell>
                                        <TableCell>{p.amountOrdered === "0 x null null" ? "0" : p.amountOrdered}</TableCell>
                                        <TableCell>{formatDateTime(p.orderedAt)}</TableCell>
                                        <TableCell>{p.requestorName}</TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </div>
                        : null}
                </>}
        </Box>
    );
}