import React, { useContext, useEffect, useMemo } from 'react';
import { FormControlProps, Input, MenuItem, Select } from '@mui/material';
import { NewApplicationContext } from '../../utils/NewApplicationContext';
import { useFormControls, ValidationRule } from '../../utils/UseFormControls';
import MyFormControl from '../my_form/MyFormControl';
import { quantityUnitOptions } from '../../types/quantityUnit/QuantityUnitOptions';
import PackagingDto from '../../dtos/PackagingDto';

interface OrderFormProps {
    order?: {
        selectedIdState: [string, React.Dispatch<React.SetStateAction<string>>],
        modelState: [PackagingDto, React.Dispatch<React.SetStateAction<PackagingDto>>],
        isFormValidState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
    }
}

export default function OrderForm(props: OrderFormProps) {
    const newApplicationContext = useContext(NewApplicationContext);
    const orderStates = props.order ?? newApplicationContext.packaging;
    const orderState = orderStates.modelState;
    const setIsFormValid = orderStates.isFormValidState[1];

    const fields: {
        attributeName: keyof PackagingDto,
        inputTag: any,
        validationRules?: ValidationRule[],
        inputProps?: any,
        formControlProps?: FormControlProps,
    }[] = [
            {
                attributeName: "amount",
                inputTag: Input,
                inputProps: { type: "number" },
                formControlProps: { sx: { width: "49%" }, fullWidth: false }
            },
            {
                attributeName: "unit",
                inputTag: Select,
                inputProps: {
                    children:
                        quantityUnitOptions.map((unit) => {
                            return <MenuItem value={unit} key={unit}>{unit}</MenuItem>;
                        })
                },
                formControlProps: { sx: { width: "49%" }, fullWidth: false }
            },
            {
                attributeName: "link",
                inputTag: Input,
            },
        ];

    let validationRules: { [Property in keyof PackagingDto]: ValidationRule[] } = {} as { [Property in keyof PackagingDto]: ValidationRule[] };
    fields.forEach(field => {
        if (field.validationRules) {
            validationRules[field.attributeName] = field.validationRules;
        }
    });

    const { isValid, getHandleFieldChange } = useFormControls(orderState, validationRules);

    const isFormValid = useMemo<boolean>(isValid, [isValid]);

    useEffect(() => {
        setIsFormValid(isFormValid);
    }, [isFormValid, setIsFormValid]);

    return (
        <>
            {fields.map(tag => {
                return <MyFormControl modelState={orderState} disabled={false} inputProps={tag.inputProps} getHandleChange={getHandleFieldChange}
                    attributeName={tag.attributeName} inputTag={tag.inputTag} validationRules={tag.validationRules} formControlProps={tag.formControlProps} />;
            })}
        </>
    );
}