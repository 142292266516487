import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Button, Container, IconButton, Input, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import NewChemicalModel from '../../models/NewChemicalModel';
import { chemicalStateOptions } from '../../types/chemicalState/ChemicalStateOptions';
import { NewApplicationContext } from '../../utils/NewApplicationContext';
import { dangerousSubstanceCategories, ghsSymbols } from '../../utils/Sentences';
import { T } from '../../utils/translate/translator';
import { useFormControls, ValidationRule } from '../../utils/UseFormControls';
import YesNoInput from '../my_form/YesNoInput';
import MyForm, { isMyFormField, MyFormFields, MyFormGroup } from '../my_form/MyForm';
import { useMutation, useQuery } from 'react-query';
import { fetchDelete, fetchGet, getToken } from 'wistron-layout';
import { chemicalUrls, fileApiUrl } from '../../utils/BaseUrl';
import ExtendedAutocomplete from '../my_form/extended_autocomplete/ExtendedAutocomplete';
import PhrasesOptions from '../../dtos/PhrasesOptions';
import { Delete } from '@mui/icons-material';
import ChemicalDto from '../../dtos/ChemicalDto';
import MetaInfo from '../../models/file/MetaInfo';
import packageJson from '../../../package.json';
import { LayoutContext } from 'wistron-layout';
import { Upload } from 'tus-js-client';
import { useParams } from 'react-router-dom';

interface ChemicalFormProps {
    chemical?: {
        modelState: [NewChemicalModel, React.Dispatch<React.SetStateAction<NewChemicalModel>>],
        isFormValidState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
        selectedIdState: [string, React.Dispatch<React.SetStateAction<string>>]
    },
    blFileState: File[]
    setBlFileState: (blFileState: File[]) => void
    chemicalData?: ChemicalDto
    application?: boolean
}

const acceptedFileTypes = ["application/pdf", "application/msword"];

export default function ChemicalForm(props: ChemicalFormProps) {
    const { blFileState, setBlFileState } = props;
    const newApplicationContext = useContext(NewApplicationContext);
    const chemicalStates = props.chemical ?? newApplicationContext.chemical;
    const setIsFormValid = chemicalStates.isFormValidState[1];
    const formDisabled = chemicalStates.selectedIdState[0] !== "new";
    const chemicalState = chemicalStates.modelState;
    const [uploadedFiles, setUploadedFiles] = useState<MetaInfo[]>([]);
    const { snackbar } = useContext(LayoutContext);
    const { id } = useParams();

    const { data: casOptions = [] } = useQuery<string[]>(["svhcCasSelectOptions"], ({ signal }) => fetchGet(chemicalUrls.casOptions, signal));

    const { data: phrasesOptions } = useQuery<PhrasesOptions>(["phraseSelectOptions"], ({ signal }) => fetchGet(chemicalUrls.phrasesOptions, signal));

    const { data: metaData, refetch } = useQuery<MetaInfo[], Response>(["meta", props.chemicalData?.id],
        ({ signal }) => fetchGet(`${fileApiUrl}/v1/meta?appName=${packageJson.name}&subId=${props.chemicalData?.id}`,
            signal),
        {
            refetchOnWindowFocus: false,
        });

    const { mutate: deleteItem } = useMutation((id: string) => fetchDelete(`${fileApiUrl}/v1?appName=${packageJson.name}&id=${id}`), {
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); refetch(); },
        onSuccess: () => refetch()
    });

    const fields: MyFormFields<NewChemicalModel> = [
        {
            attributeName: "blPublicationDate",
            inputTag: Input,
            validationRules: [["maxLength", 255]]
        },
        {
            attributeName: "name",
            inputTag: Input,
            validationRules: ["required", ["maxLength", 255]],
            label: T("NameInputExact")
        },
        {
            attributeName: "supplier",
            inputTag: Input,
            validationRules: ["required", ["maxLength", 255]]
        },
        {
            attributeName: "state",
            inputTag: Select,
            inputProps: {
                children:
                    chemicalStateOptions.map((state) => {
                        return <MenuItem value={state} key={state}>{state}</MenuItem>;
                    })
            }
        },
        {
            attributeName: "density",
            inputTag: Input,
            inputProps: { type: "number" }
        },
        {
            attributeName: "flashpoint",
            label: T("FlashpointUnit"),
            inputTag: Input,
            inputProps: { type: "number" }
        },
        {
            attributeName: "cas",
            inputTag: Autocomplete,
            inputProps: { multiple: true, freeSolo: true, options: casOptions }
        },
        {
            attributeName: "hsentences",
            inputTag: ExtendedAutocomplete,
            inputProps: { options: phrasesOptions?.hphrases ?? [] }
        },
        {
            attributeName: "psentences",
            inputTag: ExtendedAutocomplete,
            inputProps: { options: phrasesOptions?.pphrases ?? [] }
        },
        {
            attributeName: "ghsSymbol",
            inputTag: Autocomplete,
            inputProps: {
                options: ghsSymbols, multiple: true,
                renderOption: (props: React.HTMLAttributes<HTMLLIElement>, ghs: string) =>
                    <li {...props}>
                        <img src={`/img/${ghs.toLowerCase()}.png`} height="40px" style={{ marginRight: "0.5em" }} alt={"ghs icon"} />
                        {ghs}
                    </li>
            }
        },
        {
            attributeName: "dangerousSubstanceCategories",
            inputTag: Autocomplete,
            inputProps: { multiple: true, options: dangerousSubstanceCategories }
        },
        {
            attributeName: "dangerousByWaterLaw",
            inputTag: YesNoInput
        },
        {
            attributeName: "extraDangerousByWaterLaw",
            inputTag: YesNoInput
        },
        {
            attributeName: "restrictedForPregnants",
            inputTag: YesNoInput
        },
        {
            attributeName: "restrictedForYouthful",
            inputTag: YesNoInput
        },
        {
            attributeName: "khsRulesRecommended",
            inputTag: YesNoInput
        },
        {
            attributeName: "voc",
            inputTag: Input,
            inputProps: { type: "number" }
        },
        {
            groupName: T("FirstAid"),
            fields: [
                {
                    attributeName: "firstAidGeneral",
                    inputTag: TextField
                },
                {
                    attributeName: "firstAidInhalation",
                    inputTag: TextField
                },
                {
                    attributeName: "firstAidSkinContact",
                    inputTag: TextField
                },
                {
                    attributeName: "firstAidEyeContact",
                    inputTag: TextField
                },
                {
                    attributeName: "firstAidIngestion",
                    inputTag: TextField
                }
            ]
        },
        {
            groupName: T("FireExtinguishers"),
            fields: [
                {
                    attributeName: "fireExtinguisherSuitable",
                    inputTag: TextField,
                    validationRules: [["maxLength", 255]]
                },
                {
                    attributeName: "fireExtinguisherNonSuitable",
                    inputTag: TextField,
                    validationRules: [["maxLength", 255]]
                }
            ]
        },
        {
            groupName: T("BodyProtection"),
            fields: [
                {
                    attributeName: "protectionAirways",
                    inputTag: TextField
                },
                {
                    attributeName: "protectionHands",
                    inputTag: TextField
                },
                {
                    attributeName: "protectionEyes",
                    inputTag: TextField
                },
                {
                    attributeName: "protectionSkinAndBody",
                    inputTag: TextField
                }
            ]
        },
        {
            groupName: T("DisposalConsiderations"),
            fields: [
                {
                    attributeName: "wasteTypeCode",
                    inputTag: TextField,
                    validationRules: [["maxLength", 255]]
                },
                {
                    attributeName: "wasteType",
                    inputTag: TextField,
                    validationRules: [["maxLength", 255]]
                },
                {
                    attributeName: "wasteSubgroup",
                    inputTag: TextField,
                    validationRules: [["maxLength", 255]]
                },
                {
                    attributeName: "wasteGroup",
                    inputTag: TextField,
                    validationRules: [["maxLength", 255]]
                }
            ]
        },
        {
            groupName: T("ManipulationAndStorage"),
            fields: [
                {
                    attributeName: "manipulation",
                    inputTag: TextField,
                },
                {
                    attributeName: "storage",
                    inputTag: TextField,
                }
            ]
        }
    ];

    let validationRules = getValidationRules(fields);

    const { isValid, getHandleFieldChange } = useFormControls(chemicalState, validationRules);

    const isFormValid = useMemo<boolean>(isValid, [isValid]);

    useEffect(() => {
        setIsFormValid(isFormValid && (blFileState.length !== 0));
    }, [isFormValid, blFileState, setIsFormValid]);

    const removeSelectedFile = (name: string) => () => {
        const filtered = blFileState.filter(file => file.name !== name);
        setBlFileState(filtered);
    };

    useEffect(() => {
        if (metaData) {
            const array: MetaInfo[] = [];
            metaData?.forEach(file => array.push(file));
            setUploadedFiles(array);
        }
    }, [metaData]);

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event?.target?.files;
        if (!files) return;

        const arrayOfFiles = Array.from(files);

        if (id) {
            arrayOfFiles.forEach(async (file) => {
                const upload = new Upload(file, {
                    endpoint: fileApiUrl + "/v1/upload",
                    chunkSize: 1048576, //1MB
                    metadata: {
                        appName: packageJson.name,
                        subId: id,
                        filename: file.name,
                        filetype: file.type
                    },
                    headers: { "Authorization": `Bearer ${await getToken()}` },
                    onError: (error) => {
                        if (error instanceof Error) {
                            snackbar({ message: error.message ?? "", severity: 'error' });
                        }
                    },
                    onSuccess: () => refetch()
                });
                return upload.start();
            });
        } else {
            setBlFileState(blFileState.concat(arrayOfFiles));
        }
    };

    const removeUploadedFile = (id: string) => () => {
        deleteItem(id);
    };

    return (
        <>
            {!props.application && <InputLabel sx={{ ml: 2 }} required shrink>{T("BlFile")}</InputLabel>}
            {!props.application && <Button sx={{ ml: 2, mb: 2 }} variant="contained" component="label">
                <input type="file" hidden style={{ marginTop: "1em" }} accept={acceptedFileTypes.join(",")} onChange={handleUpload} multiple /> {T("Upload")}</Button>}
            {blFileState.length !== 0 && <Typography sx={{ ml: 1.75, mt: 1, fontSize: 13, color: "#757575" }}>{T("ChosenFiles")}</Typography>}
            {blFileState.map((file) =>
                <Container key={file.name} sx={{ display: "flex" }}>
                    <Typography sx={{ mt: 1 }}>{file.name}</Typography>
                    <IconButton onClick={removeSelectedFile(file.name)}>
                        <Delete color="primary" />
                    </IconButton>
                </Container>
            )}
            {uploadedFiles.length > 0 && <Typography sx={{ ml: 1.75, mt: 1, fontSize: 13, color: "#757575" }}>{T("UploadedFiles")}</Typography>}
            {uploadedFiles.map((file) =>
                <Container key={file.id} sx={{ display: "flex" }}>
                    <Typography sx={{ mt: 1 }}>{file.fileName}</Typography>
                    <IconButton onClick={removeUploadedFile(file.id)}>
                        <Delete color="primary" />
                    </IconButton>
                </Container>
            )}
            <MyForm fields={fields} getHandleFieldChange={getHandleFieldChange} isDisabled={formDisabled} modelState={chemicalState} />
        </>
    );
}

function getValidationRules(fields: MyFormFields<NewChemicalModel>) {
    let validationRules: { [Property in keyof NewChemicalModel]: ValidationRule[] } = {} as { [Property in keyof NewChemicalModel]: ValidationRule[] };
    fields.forEach(field => {
        if (isMyFormField(field)) {
            if (field.validationRules) {
                validationRules[field.attributeName as keyof NewChemicalModel] = field.validationRules;
            }
        } else {
            getValidationRules((field as MyFormGroup<NewChemicalModel>).fields);
        }
    });
    return validationRules;
}