import { Autocomplete, AutocompleteRenderInputParams, FormControl, InputLabel, TextField } from "@mui/material";
import { uniqBy } from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import { fetchGet } from "wistron-layout";
import PeopleModel from "../../models/PeopleModel";
import { peoplesoftUrl } from "../../utils/BaseUrl";
import { T } from "../../utils/translate/translator";
import Loading from "./Loading";


export interface PersonAutocompleteModel {
    label: string,
    id: string
}

interface PersonAutocompleteProps {
    personState: [PersonAutocompleteModel | undefined, (person: PersonAutocompleteModel | undefined) => void]
    labelTKey: string
    defaultPersonId: string | undefined
}

export default function PersonAutocomplete(props: PersonAutocompleteProps) {

    const [peopleList, setPeopleList] = useState([] as PersonAutocompleteModel[]);

    useQuery<PeopleModel[]>(["people"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/employee?search=status==Active&fields=employeeId,nameTitleCase,departmentId`, signal), {
        onSuccess: (data: PeopleModel[]) => {
            const uniqueUsers = uniqBy(data, 'nameTitleCase');
            setPeopleList(uniqueUsers.map((person) => {
                return {
                    id: person.employeeId,
                    label: `${person.nameTitleCase} (${person.departmentId})`
                };
            }));
            if (props.defaultPersonId !== undefined) {
                const person = data.find(p => p.employeeId.toUpperCase() === props.defaultPersonId!.toUpperCase());
                props.personState[1]({
                    id: props.defaultPersonId,
                    label: `${person?.nameTitleCase} (${person?.departmentId})`
                });
            }
        }
    });

    if (props.personState[0] === undefined) {
        return <Loading />;
    }

    return <FormControl
        margin="normal"
        sx={{
            minWidth: "15em",
            width: "50%"
        }}
    >
        <InputLabel
            shrink
        >
            {T(props.labelTKey)}
        </InputLabel>
        <Autocomplete
            options={peopleList}
            value={props.personState[0]}
            defaultValue={props.personState[0]}
            onChange={(_, value) => props.personState[1](value!)}
            renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} variant="standard" margin="normal" />}
        />
    </FormControl>;
}