import { SelectChangeEvent } from '@mui/material';
import React from 'react';
import { ValidationRule } from '../../utils/UseFormControls';
import MyFormControl from './MyFormControl';
import HideableContent from '../common/HideableContent/HideableContent';

export function isMyFormField<T extends {[key: string]: any}>(object: any): object is MyFormField<T> {
    return object.attributeName;
}

interface MyFormField<T extends {[key: string]: any}> {
    attributeName: keyof T
    inputTag: any
    inputProps?: any
    validationRules?: ValidationRule[]
    label?: string
}

export interface MyFormGroup<T extends {[key: string]: any}> {
    groupName: string
    fields: MyFormFields<T>
}

export type MyFormFields<T extends {[key: string]: any}> = (MyFormField<T> | MyFormGroup<T>)[]

interface MyFormProps<T extends {[key: string]: any}> {
    fields: MyFormFields<T>,
    modelState: [T, React.Dispatch<React.SetStateAction<T>>],
    isDisabled: boolean,
    getHandleFieldChange: (inputTag: any) => ((e: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | ((e: any, value: any) => void)
}

export default function MyForm<T extends {[key: string]: any}>(props: MyFormProps<T>) {
    function processField(field: MyFormGroup<T> | MyFormField<T>) {
        if (isMyFormField<T>(field)) {
            return <MyFormControl key={field.attributeName as string}
                modelState={props.modelState} disabled={props.isDisabled} inputProps={field.inputProps} getHandleChange={props.getHandleFieldChange}
                label={field.label} attributeName={field.attributeName} inputTag={field.inputTag} validationRules={field.validationRules} />;
        } else {
            return (<HideableContent title={field.groupName} key={field.groupName}>
                {field.fields.map(childrenField => processField(childrenField))}
            </HideableContent>);
        }
    }
    return (
        <>
        {props.fields.map(childrenField => processField(childrenField))}
        </>
    );
}