import { Fragment, useCallback, useContext, useEffect, useMemo } from "react";
import { DataGridPro, GridActionsCellItem, GridCellParams, GridColDef, GridEnrichedColDef, GridRowId, GridRowModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { useMutation, useQuery } from "react-query";
import { fetchDelete, fetchGet, fetchPut, hasRole, LayoutContext } from "wistron-layout";
import { getColumnDef } from "../utils/RenderUtils";
import { T } from "../utils/translate/translator";
import { chemicalUrls } from "../utils/BaseUrl";
import { Add, Delete, Info } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ChemicalDto from "../dtos/ChemicalDto";
import { TableContainer, useDataGrid } from "wistron-x-data-grid-hook";
import { Button, ToolbarProps } from "@mui/material";
import { chemicalStateOptions } from "../types/chemicalState/ChemicalStateOptions";
import { dangerousSubstanceCategories, ghsSymbols } from "../utils/Sentences";
import { UserRoles } from "../utils/UserRoles";
import { AutocompleteEditInputCell } from "../components/common/AutocompleteEditInputCell";
import { ExtendedAutocompleteModel } from "../components/my_form/extended_autocomplete/ExtendedAutocomplete";

export default function ChemicalList() {
    const layout = useContext(LayoutContext);
    const navigate = useNavigate();

    useEffect(() => {
        layout.setTitle(T("ApprovedChemicalsInWcz"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { defaultOptions, apiRef, deleteRow } = useDataGrid("chemicalList");


    const { data = [], refetch } = useQuery<ChemicalDto[], Response>("approvedChemicalList", ({ signal }) => fetchGet(chemicalUrls.list, signal));


    const { mutate: deleteChemical } = useMutation((id: GridRowId) =>
        fetchDelete(chemicalUrls.delete + id),
        {
            onSuccess: () => {
                layout.snackbar({ message: T("Chemical") + " " + T("deleted"), severity: 'success' });
                refetch();
            }
        }
    );

    const updateChemical = useMutation((chemical: ChemicalDto) => fetchPut(`${chemicalUrls.update}/${chemical.id}`, chemical));

    const processRowUpdate = async (row: GridRowModel<ChemicalDto>): Promise<ChemicalDto> => {
        const toSentObject = row;
        delete toSentObject.svhcSubstance;
        delete toSentObject.isActive;
        updateChemical.mutateAsync(toSentObject);
        return row;
    };

    const getColumnDefCsrEditable = (fieldName: keyof ChemicalDto, type?: string, options: Partial<GridColDef> = {} as GridColDef): GridColDef => {
        return getColumnDef(fieldName, type, {
            ...options,
            editable: hasRole([UserRoles.CSR]) && (options?.editable === undefined || options.editable)
        });
    };

    const doubleClick = useCallback((params: GridCellParams) => {
        navigate(`/chemical/${params.id}`);
    }, [navigate]);

    const handleAddNewChemical = useCallback(() => {
        navigate("/create_chemical");
    }, [navigate]);

    const showAddChemical = () => {
        if (hasRole([UserRoles.CSR])) {
            return true;
        }
        return false;
    };

    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
                {showAddChemical() && <Button size="small" type="button" color="primary" onClick={handleAddNewChemical} startIcon={<Add />}>{T("AddNewChemical")}</Button>}
            </GridToolbarContainer>
        );
    };

    const actions: GridEnrichedColDef = useMemo(() => {
        return {
            field: 'actions', type: 'actions', width: 80, hideable: false,
            getActions: (params: any) => [
                <GridActionsCellItem
                    key={params.id}
                    icon={<Info />}
                    label={T("Detail")}
                    onClick={() => navigate(`/chemical/${params.id}`)}
                />,
                <GridActionsCellItem
                    sx={{ display: !hasRole([UserRoles.CSR]) ? "none" : undefined }}
                    icon={<Delete />}
                    label={T("Delete")}
                    onClick={deleteRow(params.id, deleteChemical)}
                />,
            ]
        };
    }, [deleteChemical, deleteRow, navigate]);

    const columns: GridEnrichedColDef[] = useMemo(() => [
        actions,
        getColumnDefCsrEditable("name"),
        getColumnDefCsrEditable("supplier"),
        getColumnDefCsrEditable("blNumber"),
        getColumnDefCsrEditable("blPublicationDate"),
        getColumnDefCsrEditable("state", undefined, {
            type: "singleSelect",
            valueOptions: chemicalStateOptions
        }),
        getColumnDefCsrEditable("density", "number"),
        getColumnDefCsrEditable("hazardClass"),
        getColumnDefCsrEditable("flashpoint", "number"),
        getColumnDefCsrEditable("cas", undefined, {
            valueFormatter: ({ value }: GridValueFormatterParams<ExtendedAutocompleteModel[]>) => value.join("; ")
        }),
        getColumnDefCsrEditable("hsentences", undefined, {
            editable: false,
            valueFormatter: ({ value }: GridValueFormatterParams<ExtendedAutocompleteModel[]>) => value.map(v => v.shortText).join("; ")
        }),
        getColumnDefCsrEditable("psentences", undefined, {
            editable: false,
            valueFormatter: ({ value }: GridValueFormatterParams<ExtendedAutocompleteModel[]>) => value.map(v => v.shortText).join("; ")
        }),
        getColumnDefCsrEditable("ghsSymbol", undefined, {
            valueFormatter: ({ value }: GridValueFormatterParams<ExtendedAutocompleteModel[]>) => value.join("; ")
        }),
        getColumnDefCsrEditable("svhcSubstance", "boolean", {
            editable: false
        }),
        getColumnDefCsrEditable("isActive", "boolean", {
            editable: false
        }),
        getColumnDefCsrEditable("dangerousSubstanceCategories", undefined, {
            valueFormatter: ({ value }: GridValueFormatterParams<ExtendedAutocompleteModel[]>) => value.join("; ")
        }),
        getColumnDefCsrEditable("dangerousByWaterLaw", "boolean"),
        getColumnDefCsrEditable("extraDangerousByWaterLaw", "boolean"),
        getColumnDefCsrEditable("restrictedForPregnants", "boolean"),
        getColumnDefCsrEditable("restrictedForYouthful", "boolean"),
        getColumnDefCsrEditable("khsRulesRecommended", "boolean"),
        getColumnDefCsrEditable("voc", "number"),
        getColumnDefCsrEditable("firstAidGeneral"),
        getColumnDefCsrEditable("firstAidInhalation"),
        getColumnDefCsrEditable("firstAidSkinContact"),
        getColumnDefCsrEditable("firstAidEyeContact"),
        getColumnDefCsrEditable("firstAidIngestion"),
        getColumnDefCsrEditable("fireExtinguisherSuitable"),
        getColumnDefCsrEditable("fireExtinguisherNonSuitable"),
        getColumnDefCsrEditable("protectionAirways"),
        getColumnDefCsrEditable("protectionHands"),
        getColumnDefCsrEditable("protectionEyes"),
        getColumnDefCsrEditable("protectionSkinAndBody"),
        getColumnDefCsrEditable("wasteTypeCode"),
        getColumnDefCsrEditable("wasteType"),
        getColumnDefCsrEditable("wasteSubgroup"),
        getColumnDefCsrEditable("wasteGroup"),
        getColumnDefCsrEditable("manipulation"),
        getColumnDefCsrEditable("storage")
    ], [actions]);

    return (
        <Fragment>
            <TableContainer>
                <DataGridPro rows={data} columns={columns} {...defaultOptions} components={{ Toolbar: customToolbar }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "name", sort: "asc" }]
                        }
                    }}
                    componentsProps={{
                        toolbar: {
                            apiRef: apiRef,
                            hideAdd: true
                        } as ToolbarProps
                    }}
                    editMode="row"
                    processRowUpdate={processRowUpdate}
                    onCellDoubleClick={doubleClick}
                />
            </TableContainer>
        </Fragment>
    );
}