import { Delete } from "@mui/icons-material";
import { Box } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridEnrichedColDef, GridSlotsComponent } from "@mui/x-data-grid-pro";
import { CapitalizeFirst } from "./StringUtils";
import { T } from "./translate/translator";

const getActionsColumn = (handleDelete: (id: string) => void): GridEnrichedColDef => {
    const confirmDelete = (id: string, name: string) => {
        const recordString = name ? ` ${T("Record").toLowerCase()} ${name}` : "";

        if (!window.confirm(T("Remove") + recordString + "?"))
            return;

        handleDelete(id);
    };
    return {
        field: 'actions', type: 'actions', width: 50, hide: false,
        getActions: (params: any) => [
            <GridActionsCellItem
                icon={<Delete />}
                label={T("Delete")}
                onClick={() => confirmDelete(params.id, params.row.name)}
            />,
        ]
    };
};

const getColumnDef = (fieldName: string, type?: string, options: Partial<GridColDef> = {} as GridColDef): GridColDef => {
    let returnObject: GridColDef = {
        field: fieldName,
        headerName: T(CapitalizeFirst(fieldName)),
        valueGetter: (params) => {
            if ((params.colDef.headerName === "Hazard class" || params.colDef.headerName === "Třída nebezpečnosti") && params.value === "I.") {
                return params.value + T("LowBoiling");
            }
            return params.value;
        },
        flex: 1,
        minWidth: 150,
        maxWidth: 1000,
        editable: true,
    };
    if (type)
        returnObject = {
            ...returnObject,
            type: type
        };
    if (type === "boolean") {
        returnObject = {
            ...returnObject,
            renderCell: (params) => {
                if (params.value === undefined || params.value === null)
                    return "";
                return params.value ? T("Yes") : T("No");
            }
        };
    }
    returnObject = {
        ...returnObject,
        ...options
    };
    return returnObject;
};

const noRowsCtor = () => {
    return (
        <Box style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute" }}>
            {T("NoDataToDisplay")}
        </Box>
    );
};

const defaultDataGridComponents: Partial<GridSlotsComponent> = {
    NoRowsOverlay: noRowsCtor
};

export { getActionsColumn, getColumnDef, defaultDataGridComponents };