import { Add, CancelOutlined } from "@mui/icons-material";
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { T } from "../../../utils/translate/translator";
import HideableContent from "../../common/HideableContent/HideableContent";
import ExtendedAutocompleteLine from "./ExtendedAutocompleteLine";

import styles from "./ExtendedAutocomplete.module.css";
import { CapitalizeFirst } from "../../../utils/StringUtils";

export type ExtendedAutocompleteModel = {
    shortText: string,
    params: string[],
    fullText: string
}

export function isExtendedAutocompleteModel(o: any): o is ExtendedAutocompleteModel {
    return typeof o.shortText === "string"
        && typeof o.fullText === "string"
        && Array.isArray(o.params)
        && o.params.every((param: any) => typeof param === "string");
}

export function isExtendedAutocompleteModelArray(o: any): o is ExtendedAutocompleteModel[] {
    return Array.isArray(o) && o.length > 0 && o.every(item => isExtendedAutocompleteModel(item));
}

export type ExtendedAutocompleteOptions = {
    shortText: string, // or readable id
    fullText: string //with ... where params are to be input
}

export interface ExtendedAutocompleteProps {
    name: string,
    options: ExtendedAutocompleteOptions[],
    value: ExtendedAutocompleteModel[]
    setValue: (newValue: ExtendedAutocompleteModel[]) => void
}

export default function ExtendedAutocomplete(props: ExtendedAutocompleteProps) {

    const [selectedId, setSelectedId] = useState<string>("");
    //const [props.value, props.setValue] = useState<ExtendedAutocompleteModel[]>([])

    const handleAdd = () => {
        if (selectedId && !props.value.find(as => as.shortText === selectedId))
            props.setValue([
                ...props.value,
                {
                    shortText: selectedId,
                    fullText: props.options.find(o => o.shortText === selectedId)?.fullText ?? "",
                    params: []
                }
            ]);
        setSelectedId("");
    };

    const handleRemove = (sentenceCode: string) => {
        props.setValue(props.value.filter(ca => ca.shortText !== sentenceCode));
    };

    return (
        <HideableContent
            title={T(CapitalizeFirst(props.name))}
        >
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "1rem"
            }}>
                <Autocomplete
                    key={props.name}
                    onChange={(_e, value) => setSelectedId(value ?? "")}
                    value={selectedId}
                    options={props.options.map(o => o.shortText).filter(s => !props.value.map(a => a.shortText).includes(s))}
                    renderInput={props => <TextField {...props} />}
                    sx={{
                        display: "inline-block",
                        minWidth: "20em"
                    }}
                />
                <Button onClick={handleAdd} sx={{ lineHeight: "1em" }} disabled={!selectedId}>
                    {T("Add")}
                    <Add />
                </Button>
            </div>
            <div key={props.value.length}>
                {props.value ? props.value.sort((a, b) => a.shortText.localeCompare(b.shortText)).map((sentence, i) => {
                    return <div key={"added_" + sentence.shortText} className={i % 2 === 1 ? styles.even + " " + styles.addedLine : styles.addedLine}>
                        <IconButton onClick={() => handleRemove(sentence.shortText)}>
                            <CancelOutlined color="error" />
                        </IconButton>
                        {sentence.shortText} - <ExtendedAutocompleteLine
                            fullSentence={sentence.fullText}
                            paramsState={[sentence.params,
                            (newParams: string[]) => props.setValue(
                                [
                                    ...props.value.filter(c => c.shortText !== sentence.shortText),
                                    {
                                        ...sentence,
                                        params: newParams
                                    }
                                ]
                            )]}
                        />
                    </div>;
                }) : null}
            </div>
        </HideableContent>
    );
}