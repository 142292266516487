export const cs = new Map<string, string>();

cs.set("Continue?", "Opravdu chcete pokračovat?");
cs.set("NoDataToDisplay", "Žádná data k zobrazení");
cs.set("YesNo-true", "Ano");
cs.set("YesNo-false", "Ne");
cs.set("NoPagePermission", "Pro zobrazení této stránky nemáte oprávnění");

cs.set("Chemical", "Chemikálie");
cs.set("Home", "Domů");
cs.set("ChemicalControl", "Chemical Control System");
cs.set("ChemicalList", "List chemikálií");
cs.set("ChemicalAssignRequest", "Nová žádost o přiřazení chemikálie");
cs.set("NewChemicalRequest", "Nová žádost o přidání chemikálie");
cs.set("AssignRequests", "Žádosti o přiřazení");
cs.set("AddRequests", "Žádosti o přidání");
cs.set("PickUpReady", "Připraveno k vyzvednutí");
cs.set("AwaitingDelivery", "Čeká na doručení");
cs.set("ChemicalOrdering", "Objednání chemikálie");
cs.set("QrScan", "QR Scan");
cs.set("ChemicalDetail", "Detail chemikálie");
cs.set("ChemicalEdit", "Editace chemikálie");
cs.set("LocationRelations", "Používáno na lokacích");
cs.set("ChemicalRelations", "Chemikálie na lokaci");
cs.set("AddNewChemical", "Přidat novou chemikálii");
cs.set("AddNewLocation", "Přidat novou lokaci");

cs.set("Id", "ID");
cs.set("Name", "Jméno");
cs.set("NameInputExact", "Jméno - opiš přesně z bezpečnostního listu");
cs.set("Bl", "BL");
cs.set("SupplierBl", "BL dodavatele");
cs.set("State", "Skupenství");
cs.set("Density", "Hustota [g/cm\u00B3]");
cs.set("IsDangerous", "Nebezpečná látka");
cs.set("Categories", "Kategorie");
cs.set("Cas", "CAS");
cs.set("MinInTonnes", "Minimum [t]");
cs.set("MaxInTonnes", "Maximum [t]");
cs.set("CrashPlanDangerous", "Havarijní plán podle zákona o vodách č.254/2001Sb. Nebezpečná ");
cs.set("CrashPlanExtraDangerous", "Havarijní plán podle zákona o vodách č.254/2001Sb. Zvlášť nebezpečná látka ");
cs.set("ForbiddenForPregnant", "Zákaz/Omezení pro těhotné, kojící zaměstnankyně");
cs.set("ForbiddenForYouthful", "Zákaz/Omezení pro mladistvé zaměstnanci");
cs.set("KhsRulesRequired", "Jsou vyžadována pravidla pro práci schválená KHS");
cs.set("ListedStationarySource", "Vyjmenovaný stacionární zdroj zákon č.201/2012 Sb. (Bod 9.6) 2");
cs.set("Location", "Lokace");
cs.set("ResponsiblePerson", "Zodpovědná osoba");
cs.set("Quantity", "Množství");
cs.set("QuantityUnit", "Jednotka množství");
cs.set("Consumption", "Spotřeba");
cs.set("IsApproved", "Schváleno");
cs.set("IsActive", "Aktivní");

cs.set("NewChemicalRequestCreated", "Žádost o novou chemikálii úspěšně vytvořena");
cs.set("AssignChemicalRequestCreated", "Žádost o přiřazení chemikálie úspěšně vytvořena");
cs.set("Approve", "Schválit");
cs.set("Remove", "Odstranit");
cs.set("Delete", "Odstranit");
cs.set("NotifyArrival", "Oznámit Příjezd");
cs.set("NotifyPickUp", "Oznámit vyzvednutí");
cs.set("ChemicalKind", "Chemikálie - druh");
cs.set("Approved", "Schváleno");
cs.set("ArrivalNotified", "Příjezd oznámen");
cs.set("PickUpNotified", "Vyzvednutí oznámeno");
cs.set("OrderDetail", "Detail objednávky");
cs.set("OrderRequests", "Žádosti o objednávky");
cs.set("ChemicalKinds", "Chemikálie - druhy");
cs.set("RequestAssignChemical", "Požádat o přidělení chemikálie");
cs.set("OrderList", "List objednávek");
cs.set("AwaitingApproval", "Čeká na schválení");
cs.set("Ordered", "Objednáno");
cs.set("Finished", "Dokončeno");
cs.set("RequestOrderOfChemical", "Žádost o objednání chemikálie");
cs.set("NewRequest", "Nová žádost");

cs.set("Status", "Status");
cs.set("ChemicalName", "Jméno chemikálie");
cs.set("Description", "Popis");
cs.set("Department", "Oddělení");
cs.set("New", "Nový");
cs.set("New(St.Rod)", "Nové");
cs.set("New(Z.Rod)", "Nová");
cs.set("SelectedChemical", "Vybraná chemikálie");
cs.set("RequestingNewChemical", "Žádání nové chemikálie");
cs.set("ChemicalSelected", "Chemikálie vybrána");
cs.set("RequestingNewLocation", "Žádání nové lokace");
cs.set("AssignedLocationSelected", "Přiřazená lokace vybrána");
cs.set("UnassignedLocationSelected", "Nepřiřazená lokace vybrána");
cs.set("Order", "Objednávka");
cs.set("Amount", "Množství");
cs.set("Pic", "PIC");
cs.set("PicDepartment", "Oddělení PIC");
cs.set("Record", "Záznam");
cs.set("LocationList", "Lokace");
cs.set("ApprovedChemicalsInWcz", "Schválené chemikálie");

// Common
cs.set("Yes", "Ano");
cs.set("No", "Ne");
cs.set("CreatedDate", "Vytvořeno");
cs.set("Detail", "Detail");
cs.set("Reject", "Zamítnout");
cs.set("Confirm", "Potvrdit");
cs.set("SaveEdit", "Uložit úpravy");
cs.set("deleted", "smazána");
cs.set("fromLocation", "z lokace");
cs.set("Control", "Řízení");
cs.set("Add", "Přidat");
cs.set("Edit", "Editovat");
cs.set("Save", "Uložit");
cs.set("CancelEditing", "Zrušit změny");
cs.set("ChangesSaved", "Změny uloženy");
cs.set("Cancel", "Zrušit");

// Dashboard
cs.set("NewApplication", "Nová žádost o\u00a0chemikálii");
cs.set("NewChemical", "Nová chemikálie");
cs.set("Applications", "Žádosti");
cs.set("MyApplications", "Mé žádosti");
cs.set("ApplicationsForMe", "Čeká na mé schválení");
cs.set("QrPrinting", "Tisk QR kódů");
cs.set("LabelPrinting", "Tisk štítků");

// New application
cs.set("Unit", "Jednotka");
cs.set("SendApplication", "Odeslat žádost");
cs.set("ChooseChemical", "Vyberte chemikálii");
cs.set("ChooseLocation", "Vyberte lokaci");
cs.set("DisplayDetails", "Zobrazit detaily");
cs.set("HideDetails", "Skrýt detaily");
cs.set("PleaseSelect", "Prosím, vyberte");
cs.set("AChemical", "chemikálii");
cs.set("ALocation", "a location");
cs.set("FillCorrectly", "Prosím, vyplňte správně formulář");
cs.set("ToContinue", "pro pokračování");
cs.set("Link", "Odkaz");
cs.set("PackagingQuantity", "Množství balení");
cs.set("PackagingLink", "Odkaz na balení");
cs.set("TotalAmount", "Celkové množství");
cs.set("LastChanged", "Naposledy změněno");
cs.set("UpdateLink", "Změnit odkaz");
cs.set("Now", "Nyní");
cs.set("InvalidLink", "Nesprávný formát odkazu");
cs.set("ChosenFiles", "Vybrané soubory:");
cs.set("UploadedFiles", "Nahrané soubory:");
cs.set("OrderWhenCreating", "Vytvořit objednávku");
cs.set("Upload", "Nahrát");
cs.set("CreateChemical", "Přidat chemikálii");
cs.set("NewLocation", "Přidat lokaci");

//Chemical
cs.set("BlNumber", "Číslo BL");
cs.set("BlPublicationDate", "Datum vydání BL");
cs.set("Supplier", "Dodavatel");
cs.set("PackingQuantity", "Množství v balení");
cs.set("PackingUnit", "Balení jednotka (l, kg)");
cs.set("CasNumber", "CAS Číslo");
cs.set("Hsentences", "H + EUH Věty");
cs.set("Psentences", "P Věty");
cs.set("Flashpoint", "Bod vzplanutí");
cs.set("FlashpointUnit", "Bod vzplanutí (v °C)");
cs.set("LowBoiling", " (nízkovroucí)");
cs.set("HazardClass", "Třída nebezpečnosti");
cs.set("GhsSymbol", "GHS Symbol Nebezpečnosti");
cs.set("SvhcSubstance", "SVHC Látka");
cs.set("DangerousSubstanceCategories", "Kategorie nebezpečnosti");
cs.set("FlammableLiquids", "P5C Hořlavé kapaliny kategorie 2 nebo 3");
cs.set("FlammableLiquidsAmount", "P5C Hořlavé kapaliny kategorie 2 nebo 3 - množství");
cs.set("FlammableAerosol", "P3a Hořlavé aerosoly");
cs.set("FlammableAerosolAmount", "P3a Hořlavé aerosoly - množství");
cs.set("FlammableGases", "P2 Hořlavé plyny");
cs.set("FlammableGasesAmount", "P2 Hořlavé plyny - množství");
cs.set("AquaticHazardE1", "E1 Nebezpečnost pro vodní prostředí");
cs.set("AquaticHazardE1Amount", "E1 Nebezpečnost pro vodní prostředí - množství");
cs.set("AquaticHazardE2", "E2 Nebezpečnost pro vodní prostředí");
cs.set("AquaticHazardE2Amount", "E2 Nebezpečnost pro vodní prostředí - množství");
cs.set("DangerousByWaterLaw", "Nebezpečná látka - Havarijní plán podle zákona o vodách č.254/2001Sb.");
cs.set("ExtraDangerousByWaterLaw", "Zvlášť nebezpečná látka - Havarijní plán podle zákona o vodách č.254/2001Sb.");
cs.set("RestrictedForPregnants", "Zákaz/Omezení pro těhotné, kojící zaměstnankyně");
cs.set("RestrictedForYouthful", "Zákaz/omezení pro mladistvé zaměstnance");
cs.set("KhsRulesRecommended", "Doporučena pravidla KHS");
cs.set("Voc", "VOC (v %)");
cs.set("Packaging", "Balení");
cs.set("LocationsNames", "Jména lokací");
cs.set("FirstAid", "První Pomoc");
cs.set("FirstAidGeneral", "První pomoc - všeobecná");
cs.set("FirstAidInhalation", "První pomoc při vdechnutí");
cs.set("FirstAidSkinContact", "První pomoc při kontaktu s kůží");
cs.set("FirstAidEyeContact", "První pomoc při kontaktu s okem");
cs.set("FirstAidIngestion", "První pomoc při požití");
cs.set("FAGeneral", "Všeobecná");
cs.set("FAInhalation", "Při vdechnutí");
cs.set("FASkinContact", "Při kontaktu s kůží");
cs.set("FAEyeContact", "Při kontaktu s okem");
cs.set("FAIngestion", "Při požití");
cs.set("FireExtinguishers", "Hasiva");
cs.set("FireExtinguisherSuitable", "Vhodné hasící prostředky");
cs.set("FireExtinguisherNonSuitable", "Nevhodné hasiva");
cs.set("BodyProtection", "Omezování expozice");
cs.set("ProtectionAirways", "Ochrana dýchacích cest");
cs.set("ProtectionHands", "Ochrana rukou");
cs.set("ProtectionEyes", "Ochrana očí");
cs.set("ProtectionSkinAndBody", "Ochrana kůže a těla");
cs.set("DisposalConsiderations", "Pokyny pro odstraňování");
cs.set("WasteTypeCode", "Kód druhu odpadu");
cs.set("WasteType", "Kód odpadu");
cs.set("WasteSubgroup", "Podskupina odpadu");
cs.set("WasteGroup", "Skupina odpadu");
cs.set("PhysicalChemicalAttributes", "Fyzické a chemické vlastnosti");
cs.set("Others", "Ostatní");
cs.set("PhraseType", "Druh věty");
cs.set("PhraseName", "Název věty");
cs.set("FullText", "Popis");
cs.set("ParamsInfo", "Dodatečné informace");
cs.set("CreatePhrase", "Vytvořit větu");
cs.set("PhraseExample", "např. P123");
cs.set("PhraseCreated", "Věta vytvořena");
cs.set("ManipulationAndStorage", "Pokyny pro manipulaci a skladování");
cs.set("Manipulation", "Manipulace");
cs.set("Storage", "Skladování");

// Application
cs.set("WaitingFor", "Čeká na");
cs.set("Requestor", "Žadatel");
cs.set("RequestorEmployeeName", "Jméno žadatele");
cs.set("PicEmployeeName", "Jméno PIC");
cs.set("ApplicationDetail", "Detail žádosti");
cs.set("LocationName", "Název lokace");
cs.set("Notice", "Poznámka");
cs.set("Notice/Reason", "Poznámka/Důvod");
cs.set("WaitingStatus", "Status");
cs.set("Withdraw", "Zrušit žádost");
cs.set("WaitingForCsr", "Čeká na CSR");
cs.set("WaitingForLocationPic", "Čeká na PIC lokace");
cs.set("WaitingForOrder", "Čeká na objednání");
cs.set("NewApplicationCreated", "Žádost byla odeslána");
cs.set("NoBlFound", "Není nahrán žádný BL");
cs.set("BlFile", "Bezpečnostní list");
cs.set("ToStatus", "Nový status");
cs.set("FromStatus", "Starý statusu");
cs.set("Approver", "Schvalovatel");
cs.set("RequestHistory", "Historie žádosti");
cs.set("Resend", "Odeslat znovu");
cs.set("InternalOrderNumber", "Interní číslo objednávky");
cs.set("AtLocation", "Na lokaci");
cs.set("Reception", "Recepce");
cs.set("Warehouse", "Sklad");
cs.set("PickedUpBy", "Převazal(a)");
cs.set("ReceivedAt", "Přijato na");
cs.set("NoOrderRelated", "Objednávka nebyla vytvořena.");
cs.set("ChemicalCreated", "Chemikálie úspěšně vytvořena");
cs.set("LocationCreated", "Lokace úspěšně vytvořena");
// Application statuses
cs.set("WAITING_FOR_LOCATION_PIC_APPROVAL", "Čeká na schválení od PIC lokace");
cs.set("WAITING_FOR_CSR_APPROVAL", "Čeká na schválení od CSR");
cs.set("WAITING_FOR_ORDER", "Čeká na objednání buyerem");
cs.set("WAITING_FOR_DELIVERY", "Čeká na doručení");
cs.set("WAITING_FOR_CSR_RECEIVE", "Čeká na dokončení přijetí od CSR");
cs.set("READY_FOR_PICKUP", "Připraveno k vyzvednutí");
cs.set("PICKED_UP", "Vyzvednuto");
cs.set("STORED", "Uskladněno");
cs.set("WITHDRAWN_BY_USER", "Staženo žadatelem");
cs.set("REJECTED", "Zamítnuto");
cs.set("DONE", "Vyřízeno");
// Application history
cs.set("MadeAt", "Provedeno");
cs.set("ByRole", "Role schvalovatele");
cs.set("Action", "Akce");

// Administration
cs.set("Administration", "Administrace");
cs.set("AllSvhcDangerousCasNumbers", "Všechny SVHC nebezpečné CAS čísla");
cs.set("SaveSvhcDangerousCasNumbers", "Uložit SVHC nebezpečné CAS čísla");
cs.set("AddSvhcDangerousCasNumbers", "Přidat SVHC nebezpečné CAS čísla");
cs.set("AddCasNumber", "Přidate CAS číslo");
cs.set("SvhcCasNumbersSaved", "SVHC CAS čísla uložena");

// New chemical form
cs.set("BlFileTooBig", "BL soubor je moc velký. Maximální povolená velikost je &0.");
cs.set("BlWrongType", "BL soubor musí být typu pdf or msword.");

// Purchase history
cs.set("PurchaseHistory", "Historie objednávek");
cs.set("AmountOrdered", "Objednané množství");
cs.set("OrderedAt", "Objednáno v");
cs.set("RequestorName", "Jméno objednavatele");

// QR printing
cs.set("QrAddedToPrint", "QR kód přidán do fronty tisku");
cs.set("Print", "Tisk");
cs.set("QrCodes", "QR kódy");
cs.set("Labels", "štítky");
cs.set("DeleteAll", "Vymazat všechny &0");
cs.set("ConfirmDeleteAll", "Vymazat všechny &0 z tisku?");
cs.set("QrPrintingTooltip", "Pro odstranění QR kódu jej vyberte kliknutím a zmáčkněte klávesu Delete.");
cs.set("Big", "Velké");
cs.set("Small", "Malé");
cs.set("LabelStyle", "Styl štítků");
cs.set("Hsentences+", "Standardní věty o nebezpečnosti");
cs.set("Psentences+", "Pokyny pro bezpečné zacházení");
cs.set("AddLabelToPrint", "Přidat do tisku štítků");
cs.set("LabelAddedToPrint", "Přidáno do tisku štítků");
cs.set("Duplicate", "Duplikovat");
