import { Button, FormControl, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import { useContext, useRef, useEffect, useState } from "react";
import { useQuery } from "react-query";
import ReactToPrint from "react-to-print";
import { fetchGet, hasRole, LayoutContext } from "wistron-layout";
import { LabelsToPrint } from "../components/printing/LabelsToPrint";
import ChemicalLabelDto from "../dtos/ChemicalLabelDto";
import { chemicalUrls } from "../utils/BaseUrl";
import LocalStorageHandler from "../utils/LocalStorageHandler";
import { T } from "../utils/translate/translator";
import { UserRoles } from "../utils/UserRoles";
import AccessDenied from "./AccessDenied";

export type LabelStyle = "Big" | "Small"

export default function LabelPrinting() {
    const storageHandler = LocalStorageHandler.getHandler("toPrintLabels");
    const { setTitle } = useContext(LayoutContext);

    const toPrintRef = useRef<HTMLDivElement>(null);
    const [chemicalIds, setChemicalIds] = useState(storageHandler.getStrings());
    const [labels, setLabels] = useState<ChemicalLabelDto[]>([]);
    const [style, setStyle] = useState<LabelStyle>("Big");

    useEffect(() => {
        setTitle(T("LabelPrinting"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    useQuery<ChemicalLabelDto[]>(["toPrintChemicalLabels", chemicalIds], ({ signal }) => fetchGet(chemicalUrls.labelData + chemicalIds, signal), {
        onSuccess: (data: ChemicalLabelDto[]) => setLabels(chemicalIds.filter(id => data.find(d => d.id === id)).map(id => data.find(d => d.id === id)!)),
        enabled: chemicalIds.length > 0
    });

    const handleDeleteAll = () => {
        if (!window.confirm(T("ConfirmDeleteAll", T("Labels")) + "?"))
            return;
        storageHandler.removeAllStrings();
        setLabels(_c => []);
        setChemicalIds(_c => []);
    };

    if (!hasRole([UserRoles.CSR])) {
        return <AccessDenied />;
    }

    return (<Paper sx={{
        margin: { xs: "0em auto", md: "1em auto" },
        padding: "1em",
        maxWidth: "900px"
    }}>
        <div>
            <Button disabled={!(chemicalIds && chemicalIds.length)} color="error" onClick={handleDeleteAll}>{T("DeleteAll", T("Labels"))}</Button>
            <ReactToPrint
                content={() => toPrintRef.current}
                trigger={() => <Button disabled={!(labels.length)}>{T("Print")}</Button>} />
            <FormControl>
                <InputLabel id="labelStyleLabel" variant="standard">{T("LabelStyle")}</InputLabel>
                <Select labelId="labelStyleLabel" value={style} onChange={(e) => setStyle(e.target.value as LabelStyle)}>
                    <MenuItem value={"Big"}>{T("Big")}</MenuItem>
                    <MenuItem value={"Small"}>{T("Small")}</MenuItem>
                </Select>
            </FormControl>
        </div>

        <div>
            <LabelsToPrint ref={toPrintRef} labels={labels} setLabels={setLabels} labelStyle={style} />
        </div>
    </Paper>);
}