import NewLocationDto from '../../../dtos/NewLocationDto';
import { T } from '../../../utils/translate/translator';

interface LocationDetailProps {
    location: NewLocationDto
}

export default function LocationDetail(props: LocationDetailProps) {
    return (
        <>
            <div>
                <span style={{ fontWeight: "bold" }}>{T("Name")}:</span> {props.location.name}
            </div>
            <div>
                <span style={{ fontWeight: "bold" }}>{T("Pic")}:</span> {props.location.picName}
            </div>
        </>
    );
}