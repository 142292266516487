import { cs } from './cs';
import { en } from './en';

const langs = new Map<string, {}>();
langs.set("cs", cs);
langs.set("en", en);

const langStoredCode: string = localStorage.getItem("langCode") || 'en';
const lang: any = langs.get(langStoredCode);

export const T = (keyWord: string, ...params: any[]): string => {
    var translateText: string = lang.get(keyWord);

    if (!translateText) {
        console.log("NoTranslate: " + keyWord);
        return "NoTranslate: " + keyWord;
    }

    params.forEach((param, i) => {
        translateText = translateText.replace("&" + i, param);
    });

    return translateText;
};