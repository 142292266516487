import { useCallback } from 'react';
import { Info } from '@mui/icons-material';
import { DataGridPro, GridActionsCellItem, GridCellParams, GridColDef, GridEnrichedColDef } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import RequestListRecordDto from '../dtos/RequestListRecordDto';
import { getColumnDef } from '../utils/RenderUtils';
import { T } from '../utils/translate/translator';
import { formatDateTime, HumanLangFromConstantCase } from '../utils/StringUtils';
import { Toolbar, useDataGrid } from 'wistron-x-data-grid-hook';
import { ToolbarProps } from '@mui/material';

interface ApplicationListProps {
    rows: RequestListRecordDto[],
    areOrders?: boolean
}

export default function ApplicationList(props: ApplicationListProps) {
    const navigate = useNavigate();

    const { defaultOptions, apiRef } = useDataGrid("applicationsList");

    const getColumnDefNoneditable = (fieldName: string, type?: string, options: Partial<GridColDef> = {} as GridColDef) => {
        return getColumnDef(fieldName, type, {
            ...options,
            editable: false
        });
    };

    const columns: GridEnrichedColDef[] = [{
        field: 'actions', type: 'actions', width: 50, hide: false,
        getActions: (params: any) => [
            <GridActionsCellItem
                icon={<Info />}
                label={T("Detail")}
                onClick={() => navigate(`/application/${params.id}`)}
            />,
        ]
    },
    ...(props.areOrders ? [getColumnDefNoneditable("internalOrderNumber")] : []),
    getColumnDefNoneditable("waitingStatus", undefined, {
        renderCell: (params) => {
            return <>{HumanLangFromConstantCase(params.value)}</>;
        }
    }),
    getColumnDefNoneditable("requestorEmployeeName"),
    getColumnDefNoneditable("chemicalName"),
    getColumnDefNoneditable("createdDate", undefined, {
        renderCell: (params) => {
            return <>{formatDateTime(params.value)}</>;
        }
    }),
    ];

    const doubleClick = useCallback((params: GridCellParams) => {
        navigate(`/application/${params.id}`);
    }, [navigate]);

    return (
        <DataGridPro rows={props.rows} columns={columns} {...defaultOptions} components={{ Toolbar: Toolbar }}
            componentsProps={{
                toolbar: {
                    apiRef: apiRef,
                    hideAdd: true
                } as ToolbarProps
            }}
            onCellDoubleClick ={doubleClick}
        />
    );
}