import { ReactElement } from 'react';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    container: {
        padding: "3rem"
    },
    link: {
        display: "block",
        position: "relative",
        margin: "6px",
        width: "300px",
        height: "130px",
        borderRadius: "2px",
        boxShadow: "0 0 3px 0 " + grey[400],
        color: "inherit",
        textDecoration: "none",
        userSelect: "none",
        WebkitUserSelect: "none",
        padding: "0.375rem 0.75rem",
        cursor: "pointer",
        transition: "box-shadow 1s",
        "&::after": {
            content: "''",
            position: "absolute",
            zIndex: "-1",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            borderRadius: "2px",
            boxShadow: "0 0 5px 1px " + grey[400],
            transition: "opacity 0.3s ease-in-out"
        },
        "&:hover::after": {
            opacity: 1
        },
        "&:hover": {
            color: "inherit",
        }
    },
    iconDiv: {
        display: "inline-block",
        height: "80px",
        width: "80px",
        position: "absolute",
        top: "-24px",
        left: "16px",
        borderRadius: "3px",
        textAlign: "center",
        "& svg": {
            fontSize: "60px",
            color: grey[100],
            verticalAlign: "middle",
            height: "100%"
        }
    },
    headerText: {
        textAlign: "right",
        color: grey[500],
        fontSize: "17px",
        lineHeight: "1.5",
        marginLeft: "90px"
    },
    dataText: {
        textAlign: "right",
        fontSize: "30px",
        lineHeight: "1.5",
        marginLeft: "90px"
    },
    footer: {
        borderTop: "1px solid " + grey[300],
        position: "absolute",
        bottom: 0,
        marginBottom: "6px",
        paddingTop: "2px",
        width: "90%",
        textAlign: "left",
        fontSize: "16px",
        color: grey[500],
        left: "50%",
        transform: "translate(-50%, 0)"
    },
    footerIcon: {
        margin: "0 4px 1px 0"
    }
});

interface DashboardButtonProps {
    headerText?: string | undefined,
    dataText?: string | number | undefined,
    footerText?: string | undefined,
    href: string,
    iconColor: string,
    icon: ReactElement
}

export default function DashboardButton(props: DashboardButtonProps) {
    const classes = useStyles();

    const footer = props.footerText ? (<div className={classes.footer}>
        {/* <Today className={classes.footerIcon} /> */}
        <span>{props.footerText}</span>
    </div>) : "";

    return (
        <div className={classes.container}>
            <Link
                to={props.href}
                className={classes.link}
            >
                <div className={classes.iconDiv} style={{ backgroundColor: props.iconColor }}>
                    {props.icon}
                </div>
                <div className={classes.headerText} style={props.dataText === undefined ? {color: "inherit"} : {}}>
                    {props.headerText}
                </div>
                <div className={classes.dataText}>
                    {props.dataText}
                </div>
                {footer}
            </Link>
        </div>
    );
}