import { useContext, useEffect, useState } from 'react';
import { Button, Divider, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import NewChemicalModel, { GetCleanNewChemicalModel } from '../../models/NewChemicalModel';
import { T } from '../../utils/translate/translator';
import ChemicalForm from './ChemicalForm';
import { NewApplicationContext } from '../../utils/NewApplicationContext';
import { useQuery } from 'react-query';
import { chemicalUrls } from '../../utils/BaseUrl';
import { fetchGet } from 'wistron-layout';
import SelectDto from '../../dtos/SelectDto';
import ChemicalDetailModel from '../../models/ChemicalDetailModel';
import Loading from '../common/Loading';

interface ChemicalSectionProps {
    //chemicalState: [NewChemicalModel, React.Dispatch<React.SetStateAction<NewChemicalModel>>],
    blFileState: File[]
    setBlFileState: (blFileState: File[]) => void
    createChemical?: boolean
}

export default function ChemicalSection(props: ChemicalSectionProps) {
    const { createChemical } = props;
    const newApplicationContext = useContext(NewApplicationContext);
    const setChemical = newApplicationContext.chemical.modelState[1];
    const [chemicalId, setChemicalId] = newApplicationContext.chemical.selectedIdState;
    const [formVisible, setFormVisible] = useState(false);

    useEffect(() => {
        if (createChemical) {
            setChemicalId("new");
        }
        if (chemicalId === "new") {
            setFormVisible(true);
        }
        else {
            setFormVisible(false);
        }
    }, [chemicalId, createChemical, setChemicalId]);

    const { data: chemicals } = useQuery<SelectDto[]>("chemicalSelect", ({ signal }) => fetchGet(chemicalUrls.select, signal));


    useQuery<ChemicalDetailModel>("chemicalDetail", ({ signal }) => fetchGet(chemicalUrls.detail + chemicalId, signal), {
        enabled: chemicalId !== " " && chemicalId !== "new",
        onSuccess: (data: any) => {
            newApplicationContext.chemical.modelState[1](data);
        }
    });


    if (!chemicals) {
        return <Loading />;
    }

    const handleChemicalSelect = (e: SelectChangeEvent<string>) => {
        setFormVisible(false);
        setChemical((chemicals.find((chemical) => chemical.id === e.target.value) ?? GetCleanNewChemicalModel()) as NewChemicalModel);
        setChemicalId(e.target.value);
    };

    return (
        <Paper className="section-paper">
            <Typography sx={{ m: 2, fontWeight: "bold", fontSize: 20 }}>{T("Chemical")}</Typography>
            {!createChemical && <Select value={chemicalId} onChange={handleChemicalSelect} sx={{ marginBottom: "1.5em" }} fullWidth>
                <MenuItem value=" " sx={{ display: 'none' }}>{T("ChooseChemical")}</MenuItem>
                <MenuItem value="new">{T("New(Z.Rod)")}</MenuItem>
                <Divider />
                {chemicals.map((chemical) => {
                    return <MenuItem value={chemical.id} key={chemical.id}>{chemical.name}</MenuItem>;
                })}
            </Select>}
            {chemicalId !== " " && (formVisible
                ? (<>
                    {"new" !== chemicalId && <Button onClick={() => { setFormVisible(false); }}>{T("HideDetails")}</Button>}
                    <ChemicalForm blFileState={chemicalId === "new" ? props.blFileState : []} setBlFileState={props.setBlFileState} />
                </>)
                : <Button onClick={() => { setFormVisible(true); }}>{T("DisplayDetails")}</Button>)}
        </Paper>
    );
}