import { useContext } from 'react';
import { Box, Button, Paper, Slider, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useState } from 'react';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import { T } from '../utils/translate/translator';
import QrsToPrint from '../components/printing/QrsToPrint';
import { fetchGet, hasRole, LayoutContext } from 'wistron-layout';
import LocalStorageHandler from '../utils/LocalStorageHandler';
import { QR_ADDRESS } from '../utils/QrConsts';
import { useQuery } from 'react-query';
import { chemicalLocationRelationUrls } from '../utils/BaseUrl';
import RelationForQrDto from '../dtos/ChemicalLocationRelationDto';
import { UserRoles } from '../utils/UserRoles';
import AccessDenied from './AccessDenied';

export default function QrPrinting() {
    const qrLocalStorageHandler = LocalStorageHandler.getHandler("toPrintQrCodes");
    const layout = useContext(LayoutContext);
    const componentRef = useRef(null);
    const [qrStrings, setQrStrings] = useState(qrLocalStorageHandler.getStrings());
    const [relations, setRelations] = useState<RelationForQrDto[]>([]);
    const [qrSize, setQrSize] = useState(4);
    useEffect(() => {
        layout.setTitle(T("QrPrinting"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useQuery<RelationForQrDto[]>(["qrsToPrint", qrStrings],
        ({ signal }) => fetchGet(chemicalLocationRelationUrls.multiple + qrStrings, signal),
        {
            onSuccess: (data: RelationForQrDto[]) => setRelations(qrStrings.filter(s => data.find(d => d.id === s)).map(qrId => data.find(d => d.id === qrId)!)),
            enabled: qrStrings.length > 0
        });

    const handleDeleteAll = () => {
        if (!window.confirm(T("ConfirmDeleteAll", T("QrCodes")) + "?"))
            return;
        qrLocalStorageHandler.removeAllStrings();
        setQrStrings(_c => []);
        setRelations(_c => []);
    };

    if (!hasRole([UserRoles.CSR])) {
        return <AccessDenied />;
    }

    return (
        <Paper sx={{ margin: { xs: "0em auto", md: "1em auto" }, padding: "1em", maxWidth: "900px" }}>
            <Button disabled={!(qrStrings && qrStrings.length)} color="error" onClick={handleDeleteAll}>{T("DeleteAll", T("QrCodes"))}</Button>
            <ReactToPrint
                content={() => componentRef.current}
                trigger={() => <Button disabled={!(relations && relations.length)}>{T("Print")}</Button>} />
            <div>
                <span>Width:</span> {qrSize} cm
                <Slider min={2} max={20} step={1} value={qrSize} onChangeCommitted={(_e, v) => setQrSize(v as number)} />
            </div>
            <QrsToPrint ref={componentRef} relalationsState={[relations, setRelations]} qrSize={qrSize} />

            <Box style={{ display: "none" }}>
                {qrStrings.map((qrId, i) =>
                    <Tooltip key={i} title={qrId} tabIndex={i}>
                        <QRCode
                            id={qrId}
                            value={QR_ADDRESS + qrId}
                            style={{ margin: "1rem" }}
                        />
                    </Tooltip>
                )}
            </Box>
        </Paper>
    );
}