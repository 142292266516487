import { useContext, useState } from 'react';
import { Alert, Box, Button, Checkbox } from '@mui/material';
import { NewApplicationContext } from '../../utils/NewApplicationContext';
import { T } from '../../utils/translate/translator';
import ChemicalSection from './ChemicalSection';
import LocationSection from './LocationSection';
import OrderSection from './OrderSection';
import { useMutation } from 'react-query';
import NewRequestDto from '../../dtos/NewRequestDto';
import { fetchPost, getToken, LayoutContext } from 'wistron-layout';
import { fileApiUrl, requestUrls } from '../../utils/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { Upload } from 'tus-js-client';
import packageJson from '../../../package.json';
import NewLocationDto from '../../dtos/NewLocationDto';
import NewChemicalModel from '../../models/NewChemicalModel';

export default function NewApplication() {
    const { snackbar } = useContext(LayoutContext);
    const newApplicationContext = useContext(NewApplicationContext);
    const navigate = useNavigate();

    const [blFileState, setBlFileState] = useState<File[]>([]);
    const [checkbox, setCheckbox] = useState<boolean>(false);

    const uploadSuccessful = () => {
        snackbar({ message: T("NewApplicationCreated"), severity: 'success' });
        navigate("/");
    };

    const uploadBlFiles = async (requestId: string) => {
        const files = blFileState;
        if (files.length === 0) {
            alert("No files selected.");
            return;
        }
        const uploadPromises = files.map(async (file) => {
            const upload = new Upload(file, {
                endpoint: fileApiUrl + "/v1/upload",
                chunkSize: 1048576, //1MB
                metadata: {
                    appName: packageJson.name,
                    subId: requestId,
                    filename: file.name,
                    filetype: file.type
                },
                headers: { "Authorization": `Bearer ${await getToken()}` },
                onError: (error) => {
                    if (error instanceof Error) {
                        snackbar({ message: error.message ?? "", severity: 'error' });
                    }
                },
                onSuccess: uploadSuccessful
            });
            return upload.start();
        });
        await Promise.all(uploadPromises);
        setBlFileState([]);
    };

    const { mutate: send } = useMutation((applicationDto: NewRequestDto) =>
        fetchPost(requestUrls.newRequest, applicationDto),
        {
            onSuccess: (response: { id: string }) => {
                if (newApplicationContext.chemical.selectedIdState[0] === "new")
                    uploadBlFiles(response.id);
                else
                    uploadSuccessful();
            }
        }
    );

    const handleSendClick = () => {
        const chemicalPart = newApplicationContext.chemical.selectedIdState[0] === "new"
            ? { chemical: newApplicationContext.chemical.modelState[0] as NewChemicalModel }
            : { chemicalId: newApplicationContext.chemical.selectedIdState[0] };
        const locationPart = newApplicationContext.location.selectedIdState[0] === "new"
            ? {
                location: {
                    ...newApplicationContext.location.modelState[0],
                    picId: newApplicationContext.location.modelState[0].pic.id,
                    picName: newApplicationContext.location.modelState[0].pic.label
                } as NewLocationDto,
            }
            : { locationId: newApplicationContext.location.selectedIdState[0] };
        const packagingPart = newApplicationContext.packaging.selectedIdState[0] === "new"
            ? { packaging: newApplicationContext.packaging.modelState[0] }
            : {
                packagingId: newApplicationContext.packaging.selectedIdState[0],
                packaging: newApplicationContext.packaging.modelState[0]
            };
        if (checkbox) {
            send({
                ...chemicalPart,
                ...locationPart,
                ...packagingPart,
                quantity: newApplicationContext.quantityState[0]
            });
        } else {
            send({
                ...chemicalPart,
                ...locationPart
            });
        }
    };

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckbox(e.target.checked);
    };

    let section4 =
        <>
            <Checkbox sx={{ ml: 3 }} value={checkbox} onChange={handleCheckbox} id="checkbox" />
            <label htmlFor="checkbox">{T("OrderWhenCreating")}</label>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button sx={{ display: "block" }} onClick={handleSendClick}>{T("SendApplication")}</Button>
            </Box>
        </>;

    let section3 =
        <>
            {checkbox && <OrderSection />}
            {section4}
        </>;
    if (newApplicationContext.location.selectedIdState[0] === " ")
        section3 = <Alert className="section-paper" severity="warning">{`${T("PleaseSelect")} ${T("ALocation")} ${T("ToContinue")}`}</Alert>;
    if (newApplicationContext.location.selectedIdState[0] === "new" && !newApplicationContext.location.isFormValidState[0])
        section3 = <Alert className="section-paper" severity="warning">{T("FillCorrectly") + " " + T("ToContinue")}</Alert>;

    let section2 =
        <>
            <LocationSection />
            {section3}
        </>;
    if (newApplicationContext.chemical.selectedIdState[0] === " ")
        section2 = <Alert className="section-paper" severity="warning">{`${T("PleaseSelect")} ${T("AChemical")} ${T("ToContinue")}`}</Alert>;
    if (newApplicationContext.chemical.selectedIdState[0] === "new" && !newApplicationContext.chemical.isFormValidState[0])
        section2 = <Alert className="section-paper" severity="warning">{T("FillCorrectly") + " " + T("ToContinue")}</Alert>;

    return (
        <Box sx={{ margin: { xs: "0em auto", md: "1em auto" }, padding: "1em", maxWidth: "800px" }}>
            <ChemicalSection blFileState={blFileState} setBlFileState={setBlFileState} />
            {section2}
        </Box>
    );
}