import { ExtendedAutocompleteModel } from "../components/my_form/extended_autocomplete/ExtendedAutocomplete";
import { ChemicalState } from "../types/chemicalState/ChemicalStateType";

export default interface NewChemicalModel {
    id: string,
    name: string,
    blNumber: string,
    blPublicationDate: string,
    supplier: string,
    state?: ChemicalState,
    density?: number,
    flashpoint: number,
    hazardClass: string,
    cas?: string[],
    hsentences: ExtendedAutocompleteModel[], //not required
    psentences: ExtendedAutocompleteModel[], //not required
    ghsSymbol: string[], //not required

    
    dangerousSubstanceCategories: string[],

    dangerousByWaterLaw: boolean,
    extraDangerousByWaterLaw: boolean,
    restrictedForPregnants: boolean,
    restrictedForYouthful: boolean,
    khsRulesRecommended: boolean,
    voc?: number,
    
    //FIRST AID
    firstAidGeneral: string,
    firstAidInhalation: string,
    firstAidSkinContact: string,
    firstAidEyeContact: string,
    firstAidIngestion: string,
    //FIRE EXTINGUISHER
    fireExtinguisherSuitable: string
    fireExtinguisherNonSuitable: string
    //BODY PROTECTIONS
    protectionAirways: string
    protectionHands: string
    protectionEyes: string
    protectionSkinAndBody: string
    //TRASH
    wasteTypeCode: string
    wasteType: string
    wasteSubgroup: string
    wasteGroup: string
    //MANIPULATION AND STORAGE RULES
    manipulation: string
    storage: string
}

export function GetCleanNewChemicalModel(): Partial<NewChemicalModel> {
    return {
        cas: [],
        hsentences: [],
        psentences: [],
        ghsSymbol: []
    };
}