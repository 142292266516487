import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Input } from '@mui/material';
import NewLocationModel from '../../models/NewLocationModel';
import { useQuery } from 'react-query';
import PeopleModel from '../../models/PeopleModel';
import { fetchGet, LayoutContext } from 'wistron-layout';
import { NewApplicationContext } from '../../utils/NewApplicationContext';
import { useFormControls, ValidationRule } from '../../utils/UseFormControls';
import MyFormControl from '../my_form/MyFormControl';
import { locationUrls, peoplesoftUrl } from '../../utils/BaseUrl';
import LocationListDto from '../../dtos/LocationListDto';
import { uniqBy } from 'lodash';
import AttributeDiv from '../common/AttributeDiv/AttributeDiv';

interface LocationFormProps {
    location?: {
        modelState: [NewLocationModel, React.Dispatch<React.SetStateAction<NewLocationModel>>],
        isFormValidState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
        selectedIdState: [string, React.Dispatch<React.SetStateAction<string>>]
    }
}

export default function LocationForm(props: LocationFormProps) {
    const isEdit: boolean = !!props.location;
    const layout = useContext(LayoutContext);
    const newApplicationContext = useContext(NewApplicationContext);
    const locationStates = props.location ?? newApplicationContext.location;
    const locationState = locationStates.modelState;
    const setIsFormValid = locationStates.isFormValidState[1];
    const formDisabled = locationStates.selectedIdState[0] !== "new";

    const [peopleList, setPeopleList] = useState([] as { label: string, id: string }[]);

    useQuery<LocationListDto, Response>("locationDetail", ({ signal }) => fetchGet(locationUrls.detail + locationStates.selectedIdState[0], signal), {
        enabled: formDisabled && peopleList.length > 0,
        onSuccess: (data: NewLocationModel) => {
            locationState[1]({
                name: data.name,
                pic: peopleList.find(p => p.id === data.pic.id)!
            });
        }
    });

    useQuery<PeopleModel[]>(["people"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/employee?search=status==Active&fields=employeeId,nameTitleCase,departmentId`, signal), {
        enabled: !isEdit,
        onSuccess: (data: PeopleModel[]) => {
            const uniqueUsers = uniqBy(data, 'nameTitleCase');
            setPeopleList(uniqueUsers.map((person) => {
                return {
                    id: person.employeeId,
                    label: `${person.nameTitleCase} (${person.departmentId})`
                };
            }));
            if (!isEdit) {
                locationState[1](previous => ({
                    ...previous,
                    pic: {
                        label: `${layout.user.name} (${layout.user.department})`,
                        id: layout.user.employeeId
                    }
                }));
            }
        }
    });


    const fields: {
        attributeName: keyof NewLocationModel,
        inputTag: any,
        validationRules?: ValidationRule[],
        inputProps?: any
    }[] = [
            {
                attributeName: "name",
                inputTag: Input,
                validationRules: ["required"]
            }
        ];
    if (!isEdit) {
        fields.push({
            attributeName: "pic",
            inputTag: Autocomplete,
            validationRules: ["required"],
            inputProps: {
                options: peopleList,
            }
        });
    }
    let validationRules: { [Property in keyof NewLocationModel]: ValidationRule[] } = {} as { [Property in keyof NewLocationModel]: ValidationRule[] };
    fields.forEach(field => {
        if (field.validationRules) {
            validationRules[field.attributeName] = field.validationRules;
        }
    });

    const { isValid, getHandleFieldChange } = useFormControls<NewLocationModel>(locationState, validationRules);

    const isFormValid = useMemo<boolean>(isValid, [isValid]);

    useEffect(() => {
        setIsFormValid(isFormValid);
    }, [isFormValid, setIsFormValid]);

    return (
        <>
            {fields.map(tag => {
                return <MyFormControl modelState={locationState} disabled={formDisabled} inputProps={tag.inputProps} getHandleChange={getHandleFieldChange}
                    attributeName={tag.attributeName} inputTag={tag.inputTag} validationRules={tag.validationRules} />;
            })}
            {isEdit
                ? <AttributeDiv labelTKey="Pic" value={locationState[0].pic.label} />
                : null}
        </>
    );
}