import { ReactNode } from 'react';

interface DashboardProps {
    children: ReactNode
}

export default function Dashboard(props: DashboardProps) {
    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap"
        }}>
            {props.children}
        </div>
    );
}