export const CapitalizeFirst = (s: string) => {
    return s[0].toUpperCase() + s.slice(1);
};

export const LowercaseNonfirst = (s: string) => {
    return s[0] + s.slice(1).toLowerCase();
};

export const PascalCaseFromConstantCase = (s: string) => { //PascalCasiIsThis CONSTANT_CASE_IS_THIS
    if (!s) return "";
    const words: string[] = s.split("_");
    const pascalledWords: string[] = words.map((word: string) => {
        return word[0] + word.slice(1).toLowerCase();
    });
    return pascalledWords.join("");
};

export function formatDate(date: string | undefined): string {
    return date ? new Date(date).toLocaleDateString() : "";
}

export function formatDateTime(dateTime: string | undefined): string {
    return dateTime ? new Date(dateTime).toLocaleString() : "";
}

export const HumanLangFromConstantCase = (s: string) => {
    if (!s) return "";
    return LowercaseNonfirst(s).replaceAll("_", " ");
};

export const ArrayToString = (a: string[] | number[]) => {
    return a.join(", ");
};

export function isValidHttpUrl(s: string) {
    let url;
    try {
        url = new URL(s);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}