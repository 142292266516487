import { Paper } from '@mui/material';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { fetchGet, hasRole, LayoutContext } from 'wistron-layout';
import ChemicalDetail from '../components/application/detail/ChemicalDetail';
import Loading from '../components/common/Loading';
import ApplicationDetailDto from '../dtos/ApplicationDetailDto';
import { chemicalLocationRelationUrls } from '../utils/BaseUrl';
import { T } from '../utils/translate/translator';
import { UserRoles } from '../utils/UserRoles';
import AccessDenied from './AccessDenied';

type QrLandingPageParams = {
    id: string
}

export default function QrLandingPage() {
    const { id } = useParams<QrLandingPageParams>();
    const layout = useContext(LayoutContext);
    const { data } = useQuery<ApplicationDetailDto, Response>("qrLanding", ({ signal }) => fetchGet(chemicalLocationRelationUrls.detail + id, signal));

    useEffect(() => {
        layout.setTitle(T("ChemicalControl"));
    });

    if (!data?.chemical)
        return <Loading />;

    if (!hasRole([UserRoles.CSR, UserRoles.BUYER, UserRoles.RECEPTION, UserRoles.WAREHOUSE])) {
        return <AccessDenied />;
        
    }
    return (<>
        <Paper sx={{ margin: { xs: "0em auto", md: "1em auto" }, padding: "1em 0", maxWidth: "800px" }}>
            <ChemicalDetail chemical={data?.chemical} showBlFile />
        </Paper>
    </>);
}